import React, { useState, useEffect } from 'react'
import * as S from './country-selector.styled'
// import { AppImgUrlAssets } from '@app/assets/app.img-url.assets'
import DivToSpecialInputsComponent from '@app/components/div-to-special-inputs-component/DivSpecialInputsComponent'
import { PrefixServices } from '@mod/create-account/services/get-prefix.service'
// const appImgUrlAssets = new AppImgUrlAssets()

interface Flag{
  id : number;
  alias : string;
  prefix : string;
  location : string
}

interface CountrySelectorComponentProps {
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const CountrySelectorComponent: React.FC<CountrySelectorComponentProps> = ({ handleChange }) => {
  // example code
  // const [pais, setPais] = useState<string>('')
  const GetPrefix = new PrefixServices()
  const [flag, setFlag] = useState<[]>([])
  const prefijo = '+52'
  /*   const handlePaisChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const selectedPais = event.target.value

    const prefijos: Record<string, string> = {
      mexico: '+52',
      colombia: '+57',
      peru: '+51'
    }

    setPais(selectedPais)
    setPrefijo(prefijos[selectedPais] || '')
  } */

  const getPhonePrefix = async () => {
    try {
      const res = await GetPrefix.getPrefix()
      if (res !== '') {
        setFlag(JSON.parse(res))
      } else {
        alert(
          'Ha ocurrido un error al descargar géneros, por favor intente de nuevo.'
        )
      }
    } catch {
      alert(
        'Ha ocurrido un error, por favor intente de nuevo.'
      )
    }
  }

  useEffect(() => {
    getPhonePrefix()
  }, [])

  return (
      <>
          <DivToSpecialInputsComponent>
            <S.InputGroup>
              <S.Selector>
                {flag?.map((element : Flag) => (
                <S.Options key={element.id}>
                    <img src={ element.location } alt="adw"/>
                </S.Options>
                ))}
              </S.Selector>
              <S.Input width="10%" type="text" value={prefijo} readOnly />
              <S.Input width="76%" type="text" placeholder='Ingresa tu número de teléfono' onChange={handleChange}/>
            </S.InputGroup>
          </DivToSpecialInputsComponent>
      </>
  )
}

export default CountrySelectorComponent
