import styled from '@emotion/styled'

interface SeparatorProps {
    width?: string
}

export const Separator = styled.div<SeparatorProps>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 15px 0px;
  gap: 16px;
  width: ${(props) => props.width || '100%'};
`

export const Line = styled.div`
  height: 0px;
  border: 1px solid #CAD2DB;
  width: 45%;
`
interface SpanProps {
    fw?: string;
    fs?: string;
    ta?: string;
    c?: string;
    lh?: string;
}
export const Span = styled.div<SpanProps>`
  font-weight: ${(props) => props.fw || '400'};
  font-size: ${(props) => props.fs || '14px'};
  line-height: ${(props) => props.lh || '120%'};
  align-items: center;
  text-align: ${(props) => props.ta || 'center'};
  color: ${(props) => props.c || '#101A27'};
`
