import React from 'react'

import { MainContainerView } from './LeadCaptureView.styled'
import { FormSection } from './sections/form-section/FormSection'

export const LeadCaptureView: React.FC = () => {
  return (
    <>
    <MainContainerView color=''>
      <FormSection/>
    </MainContainerView>
    </>
  )
}
