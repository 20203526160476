import React, { useState, useContext } from 'react'
import GeneralContainerComponent from '@app/components/general-container-component/GeneralContainerComponent'
import ContainerDesktopComponent from '@app/components/general-container-desktop/ContainerDesktopComponent'
import * as S from './PersonalInformation.styled'
import AppLabelInputComponente from '@app/components/app-label-input-component-pi/AppLabelInputComponent'
import AppFormButton from '@app/components/app-button-component/AppFormButton'
import { BirthDaySection } from './sections/BirthdaySection'
import { OtherDataSection } from './sections/OtherDataSection'
import { PersonalInfoService } from './services/personal-info.service'
import { ValidateFormService } from '@mod/personal-information/services/validate-form.service'
import { AppInputStatesEnum } from '@app/enums/app.input-states.enum'
import { AppImgUrlAssets } from '@app/assets/app.img-url.assets'
import { RegistrationContext } from '@mod/create-account/services/RegistrationContex'
import { useNavigate } from 'react-router-dom'

const appImgUrlAssets = new AppImgUrlAssets()
const iconError = appImgUrlAssets.iconError

export const PersonalInformationView: React.FC<{}> = () => {
  const navigate = useNavigate()
  const validateFormService = new ValidateFormService()
  const PInfoService = new PersonalInfoService()
  const { registrationData } = useContext(RegistrationContext)
  const idContext = registrationData.id

  const [name, setName] = useState({ campo: '', valido: AppInputStatesEnum.Initial })
  const [lastName, setLastName] = useState({ campo: '', valido: AppInputStatesEnum.Initial })
  const [lastName2, setLastName2] = useState({ campo: '', valido: AppInputStatesEnum.Initial })
  const [curp, setCurp] = useState({ campo: '', valido: AppInputStatesEnum.Initial })
  const [dateB, setDateB] = useState('')
  const [gender, setGender] = useState('')
  const [placeB, setPlaceB] = useState('')
  const [errorData, setErrorData] = useState(false)

  const handlerOnClick = async () => {
    try {
      const IsValid = validateFormService.validateForm({
        name: name.campo,
        last_name: lastName.campo,
        last_name_2: lastName2.campo,
        curp: curp.campo
      })
      if (
        IsValid.name === true &&
        IsValid.last_name === true &&
        IsValid.last_name_2 === true &&
        dateB !== '' &&
        gender !== '' &&
        placeB !== '' &&
        IsValid.curp === true
      ) {
        setErrorData(false)
        const body = {
          id: idContext,
          names: name.campo,
          lastName: lastName.campo,
          lastName_2: lastName2.campo,
          birthDate: dateB,
          curp: curp.campo,
          cSex: gender,
          birthPlace: placeB
        }
        localStorage.setItem('name', name.campo)
        const res = await PInfoService.addPersonalInfo(body)

        if (res !== '') {
          // Resultado exitoso
          navigate('/identification-registration')
        } else {
          // Algo paso hay un error
        }
      } else {
        setErrorData(true)
      }
    } catch {
      alert(
        'Ha ocurrido un error en la búsqueda, por favor intente de nuevo.'
      )
    }
  }
  const onChangeNameState = (e: React.ChangeEvent<HTMLInputElement>) => {
    try {
      const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const IsValid = validateFormService.validateForm({
          name: e.target.value,
          last_name: '',
          last_name_2: '',
          curp: ''
        })

        const NewState = IsValid.name ? AppInputStatesEnum.Valid : AppInputStatesEnum.Invalid

        setName({
          campo: e.target.value,
          valido: NewState
        })
      }
      handleChange(e)
    } catch (error) {
      alert('Por favor, intente de nuevo.')
    }
  }
  const onChangeLastNameState = (e: React.ChangeEvent<HTMLInputElement>) => {
    try {
      const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const IsValid = validateFormService.validateForm({
          name: '',
          last_name: e.target.value,
          last_name_2: '',
          curp: ''
        })

        const NewState = IsValid.last_name ? AppInputStatesEnum.Valid : AppInputStatesEnum.Invalid

        setLastName({
          campo: e.target.value,
          valido: NewState
        })
      }
      handleChange(e)
    } catch (error) {
      alert('Por favor, intente de nuevo.')
    }
  }
  const onChangeLastName2State = (e: React.ChangeEvent<HTMLInputElement>) => {
    try {
      const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const IsValid = validateFormService.validateForm({
          name: '',
          last_name: '',
          last_name_2: e.target.value,
          curp: ''
        })

        const NewState = IsValid.last_name_2 ? AppInputStatesEnum.Valid : AppInputStatesEnum.Invalid

        setLastName2({
          campo: e.target.value,
          valido: NewState
        })
      }
      handleChange(e)
    } catch (error) {
      alert('Por favor, intente de nuevo.')
    }
  }
  const goToCurp = () => {
    window.open('https://www.gob.mx/curp/', '_blank')
  }
  return (
    <GeneralContainerComponent>
      <S.TextPersonalInformation>Información <span style={{ color: '#00C1E7' }}>personal</span></S.TextPersonalInformation>
      <S.TextPersonalInformation c="#505B69" fs="14px" ta="left" fw="400" lh="130%">
        ¡Ayúdanos a completar tu información y comienza a disfrutar de todos los beneficios!
      </S.TextPersonalInformation>
      <ContainerDesktopComponent>
        <S.ContainerName>
          <AppLabelInputComponente
            value={name}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangeNameState(e)}
            label="Ingresa tus datos personales"
            placeholder="Nombre(s)"
            alt="Nombre"
            icon=""
            type="text"
            name="name"
            htmlfor=""
          />
          <AppLabelInputComponente
            value={lastName}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangeLastNameState(e)}
            placeholder="Apellido paterno"
            alt="Apellido paterno"
            icon=""
            type="text"
            name="lastName"
            htmlfor=""
          />
          <AppLabelInputComponente
            value={lastName2}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangeLastName2State(e)}
            label=""
            placeholder="Apellido materno"
            alt="Apellido materno"
            icon=""
            type="input"
            name="lastName2"
            htmlfor=""
          />
        </S.ContainerName>
        <BirthDaySection setDateB={setDateB}/>
        <OtherDataSection setGender={setGender} setPlace={setPlaceB} curp={curp} setCurp={setCurp}/>
        { !errorData
          ? null
          : (
          <S.ContainerError>
            <S.ImgError src={iconError} />
            <S.SpanError>Aún no has completado todos los datos</S.SpanError>
          </S.ContainerError>
            )
        }
        <AppFormButton hadleOnclick={handlerOnClick} typeBtn='' text="Continuar" w="100%"/>
        <S.ColumsPersonalInformation>
          <S.HrPersonalInformation />
          <S.TextPersonalInformation fw="400" fs="14px">ó</S.TextPersonalInformation>
          <S.HrPersonalInformation />
        </S.ColumsPersonalInformation>
        <AppFormButton typeBtn="text" text="Conocer mi CURP" w="100%" hadleOnclick={goToCurp}/>
      </ContainerDesktopComponent>
    </GeneralContainerComponent>
  )
}
