import React, { useState } from 'react'
// import { AppImgUrlAssets } from '@app/assets/app.img-url.assets'
import { CountrySelectorComponent } from './components/country-selector/CountrySelectorComponent'
import ComponentLabelInput from '@app/components/app-label-input-component/AppLabelInputComponent'
import { AppImgUrlAssets } from '@app/assets/app.img-url.assets'

interface CountryPhoneSelectorSectionProps {
   setPhoneNumber: (phone: string) => void;
   setValidPhone: React.Dispatch<React.SetStateAction<boolean>>
}

const appImgUrlAssets = new AppImgUrlAssets()
const userIcon = appImgUrlAssets.userIcon

export const CountryPhoneSelectorSection: React.FC<CountryPhoneSelectorSectionProps> = ({ setPhoneNumber, setValidPhone }) => {
  const [error, setError] = useState('')

  const validatePhone = (phone: string) => {
  // Regular expression to validate a phone number
    const phoneRegex = /^\d{10}$/

    if (!phone) {
      return 'El campo de número de teléfono no puede estar vacío.'
    } else if (!phoneRegex.test(phone)) {
      return 'Por favor, ingresa un número de teléfono válido (10 dígitos).'
    }
    setValidPhone(true)
    return ''
  }
  const handlePhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    const prefixedValue = '+52' + value
    setPhoneNumber(prefixedValue)
    const errorMessage = validatePhone(value)
    setError(errorMessage)
  }
  return (
        <>
          <ComponentLabelInput
              label="Ingresa tu número de teléfono"
              placeholder="Número de teléfono"
              alt="email"
              icon={userIcon}
              type="input"
              name="verification_code"
              htmlfor=""
              inputType='complex'
          >
            <CountrySelectorComponent
            handleChange={handlePhoneChange}
            />
          </ComponentLabelInput>
          {error && <p>{error}</p>}
        </>
  )
}
