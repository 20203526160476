import styled from 'styled-components'

interface DivSpecialInputsContainerProps {
    width?: string
    outline: string
}

export const DivSpecialInputsContainer = styled.div<DivSpecialInputsContainerProps>`
  width: ${(props) => props.width || '100%'};
  height: 36px;
  border: 1px solid #CAD2DB;
  border-radius: 8px;
  outline: ${(props) => props.outline};
  min-width: 160px;
  display:flex;
  align-items:center;
`
