import styled from 'styled-components'

interface GeneralContainerProps {
  w?: string;
  h?: string;
}

export const GeneralContainer = styled.div<GeneralContainerProps>`
    font-family: 'Mulish', sans-serif;
    font-style: normal;
    display: flex;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
    padding: 32px 16px;
    gap: 10px;
    width: ${(props) => props.w || 'auto'};
    height: ${(props) => props.h || 'auto'};
    background: rgb(255, 255, 255);
    box-shadow: rgba(0, 5, 12, 0.15) -8px 8px 26px;
    border-radius: 16px;
    margin: 24px;

    @media (min-width: 769px) {
      width: 630px;
      margin: 24px auto;
      padding: 32px 109px;
    }
`
