import React from 'react'
import { Grid } from '@mui/material'
import { CardComponent } from './components/CardComponente'
// import { CardComponent } from '@app/components/CardComponent'
import { FormComponente } from './components/form-component/FormComponente'

export const BlueScreenSection: React.FC<{}> = () => {
  return (
    <Grid container style={{
      backgroundColor: 'blue'
    }}>
      <Grid item sm={8}>
        <div>
          Logo principal
        </div>

        <div>
          Titulo
        </div>

        <div>
          Texto
        </div>

        <div>
          <CardComponent /* img={'mi-imagen.png'} text={'¿Por qué tener un plan de retiro?'} *//>

          <CardComponent /* img={'mi-otra-imagen.png'} text={'¿Por qué tener un plan de retiro?'} *//>

          <CardComponent /* img={'mi-otra-otra-imagen.png'} text={'¿Por qué tener un plan de retiro?'} *//>
        </div>
      </Grid>

      <Grid item sm={4}>
        <FormComponente/>
      </Grid>
    </Grid>
  )
}
