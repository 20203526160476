import React from 'react'
import { Container, Label, IconContainer, Icon, InputGroupContainer, Input, Span } from './app-label-input.syled'
import { AppInputStatesEnum } from '@app/enums/app.input-states.enum'

interface ComponentLabelInputProps {
    label?: string;
    labelExtraText?: string;
    labelHeigth?: string;
    htmlfor: string;
    alt: string;
    labelIcon?: string;
    labelIconW?: string;
    labelIconH?: string;
    icon?: string;
    placeholder: string;
    type: string;
    name: string;
    iconWidth?: string;
    value: {
      campo: string;
      valido: AppInputStatesEnum;
    };
    children?: React.ReactNode;
    inputType?: string;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const ComponentLabelInput: React.FC<ComponentLabelInputProps> = ({ label, htmlfor, alt, icon, placeholder, type, name, iconWidth, value, children, inputType, labelIcon, labelExtraText, labelHeigth, labelIconH, labelIconW, onChange, ...props }) => {
  return (
        <>
            <Container>
              { !label
                ? null
                : (
                  <Label h={labelHeigth} htmlFor={htmlfor}>
                    {labelIcon ? <IconContainer><Icon width={labelIconW} height={labelIconH} src={labelIcon} alt={alt} /></IconContainer> : null}
                    <span style={{ color: '#2B5DE8' }}>{label}</span>
                    {labelExtraText ? <Span>{labelExtraText}</Span> : null}
                  </Label>
                  )
              }
                {inputType !== 'whitout-i'
                  ? (
                    <InputGroupContainer>
                      {icon ? <IconContainer><Icon width={iconWidth} src={icon} alt={alt} /></IconContainer> : null}
                      {inputType === 'complex' ? <>{ children }</> : <Input type={type} placeholder={placeholder} name={name} value={value.campo} valido={value.valido || undefined} onChange={onChange}/>}
                    </InputGroupContainer>
                    )
                  : null
                }
            </Container>
        </>
  )
}

export default ComponentLabelInput
