import React from 'react'
import './modal-styles.css'
import userIcon from './imgs/user-icon.svg'
import buildIcon from './imgs/build.svg'

type StateModal = {
  name: string;
  branchCode: string;
};

type ModalProps = {
  onClose: () => void;
  setState: React.Dispatch<React.SetStateAction<StateModal>>;
};

const ButtonWithModal = ({ onClose, setState }: ModalProps) => {
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setState((prev) => ({ ...prev, [e.target.name]: e.target.value }))
  }

  return (
    <>
      <div className='modal' onClick={onClose}>
        <div className='modal-content'>
          <h2 className='title-text' style={{ marginBottom: '40px' }}>
            ¡Tu información
            <span className='blue-text'>ha sido guardada con éxito!</span>
          </h2>
          <p className='text-color'>
            Hemos guardado exitosamente tu información, lo cual nos permitirá
            brindarte la mejor experiencia posible en LucaPay.
          </p>
          <p className='text-color'>
            Agradecemos tu confianza en LucaPay, donde nos esforzamos por
            proporcionarte soluciones financieras confiables y personalizadas.
            ¡Nos emociona ser parte de tu éxito financiero!
          </p>
          <p className='text-color-bold'>
            Pronto recibirás una notificación a través de WhatsApp y correo
            electrónico para completar el proceso de creación de tu cuenta con
            nosotros.
          </p>
          <h2 className='title-text' style={{ margin: '40px 0' }}>
            ¿Quién te apoyó{' '}
            <span className='blue-text'>en todo el proceso?</span>
          </h2>
          <div>
            <div className='inputRows'>
              <img src={userIcon} />
              <input
                type='text'
                className='inputStyles'
                placeholder='Nombre del vendedor'
                onChange={onChange}
                name='name'
              />
            </div>
            <div className='inputRows'>
              <img src={buildIcon} />
              <input
                type='number'
                className='inputStyles'
                placeholder='Número de sucursal'
                onChange={onChange}
                name='branchCode'
              />
            </div>
            <div></div>
          </div>
          <div className='btnBox'>
            <button className='btnStyles' onClick={onClose}>
              Finalizar
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export default ButtonWithModal
