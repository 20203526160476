import { IValidateForm, IResponseValidateForm } from './dtos/validate-form.dto'
import { IValidateFormAbstraction } from './validate-form.abstraction'

export class ValidateFormService implements IValidateFormAbstraction {
  validateForm (body: IValidateForm):IResponseValidateForm {
    const resValidate = {
      name: false,
      last_name: false,
      last_name_2: false,
      curp: false
    }

    resValidate.name = /^[a-zA-ZÀ-ÿ\s]{1,40}$/.test(body.name)
    resValidate.last_name = /^[a-zA-ZÀ-ÿ\s]{1,40}$/.test(body.last_name)
    resValidate.last_name_2 = /^[a-zA-ZÀ-ÿ\s]{1,40}$/.test(body.last_name_2)
    resValidate.curp = /^[A-Z][A,E,I,O,U,X][A-Z]{2}[0-9]{2}[0-1][0-9][0-3][0-9][M,H][A-Z]{2}[B,C,D,F,G,H,J,K,L,M,N,Ñ,P,Q,R,S,T,V,W,X,Y,Z]{3}[0-9,A-Z][0-9]$/.test(body.curp)

    return resValidate
  }
}
