import React, { useEffect, useState } from 'react'
import ComponentLabelWithoutInput from '@app/components/app-label-without-input-component/AppLabelWithoutInputComponent'
import IconDivSpecialComponent from '@app/components/icon-div-special-component/IconDivSpecialComponent'
import InputPasswordComponent from './components/InputPasswordComponent'
import { AppImgUrlAssets } from '@app/assets/app.img-url.assets'
const appImgUrlAssets = new AppImgUrlAssets()
const lockIcon = appImgUrlAssets.verCodeIcon
const eyeIcon = appImgUrlAssets.eyeOffIcon

interface PasswordInputsSectionProps {
   setPass: (password: string) => void
   setValidPass: React.Dispatch<React.SetStateAction<boolean>>
}

export const PasswordInputsSection: React.FC<PasswordInputsSectionProps> = ({ setPass, setValidPass }) => {
  const [password1, setPassword1] = useState<string>('')
  const [password2, setPassword2] = useState<string>('')
  const [isValid, setIsValid] = useState<boolean>(false)

  const handlePasswordChange = (password: string, inputNumber: number) => {
    if (inputNumber === 1) {
      setPassword1(password)
      setPass(password)
    } else if (inputNumber === 2) {
      setPassword2(password)
    }
  }

  useEffect(() => {
    setIsValid(password1 === password2 && password1.length >= 8)
  }, [password1, password2])
  setValidPass(isValid)
  return (
    <>
      <ComponentLabelWithoutInput label="Crea una contraseña" htmlfor="">
        <IconDivSpecialComponent icon={lockIcon} alt="pass">
          <InputPasswordComponent
            plhl="Ingresa tu contraseña"
            icon={eyeIcon}
            alt="pass1"
            onPasswordChange={(password) => handlePasswordChange(password, 1)}
          />
        </IconDivSpecialComponent>
        <IconDivSpecialComponent icon={lockIcon} alt="pass">
          <InputPasswordComponent
            plhl="Verifica tu contraseña"
            icon={eyeIcon}
            alt="pass2"
            onPasswordChange={(password) => handlePasswordChange(password, 2)}
          />
        </IconDivSpecialComponent>
      </ComponentLabelWithoutInput>
      {isValid ? <p>Las contraseñas coinciden y son válidas</p> : <p>Las contraseñas no coinciden o no son válidas</p>}
    </>
  )
}
