import React from 'react'
import { Separator, Line, Span } from './app-separator-componnet.styled'

interface ComponentSeparatorProps {
  fw?: string;
  fs?: string;
  text: string;
}

export const ComponentSeparator: React.FC<ComponentSeparatorProps> = ({ text, fw, fs }) => {
  return (
    <>
        <Separator>
            <Line />
            <Span fw={fw} fs={fs}>{text}</Span>
            <Line />
        </Separator>
    </>
  )
}

export default ComponentSeparator
