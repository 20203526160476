import styled from 'styled-components'

interface TextVerifyAccountProps {
  fw?: string;
  fs?: string;
  ta?: string;
  c?: string;
  lh?: string;
}

export const TextVerifyAccount = styled.div<TextVerifyAccountProps>`
  font-weight: ${(props) => props.fw || '800'};
  font-size: ${(props) => props.fs || '24px'};
  line-height: ${(props) => props.lh || '120%'};
  align-items: center;
  text-align: ${(props) => props.ta || 'center'};
  color: ${(props) => props.c || '#101A27'};
`

export const ColumsVerifyAccount = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 16px;
  width: 100%;
`

export const HrVerifyAccount = styled.div`
  height: 0px;
  border: 1px solid #CAD2DB;
  width: 45%;
`
