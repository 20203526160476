import React/*, { useEffect, useState } */ from 'react'
import { Grid } from '@mui/material'
// import { AboutMeSection } from './sections/AboutMeSection'
import { BlueScreenSection } from './sections/BlueScreenSection'
// import { AppImgUrlAssets } from '@app/assets/app.img-url.assets'
// import { MockExampleService } from './services/mock-example.service'
// import { ExampleService } from './services/example.service'

export const AboutView: React.FC<{}> = () => {
  // Ejemplo de servicios
  // const exampleService = new ExampleService()
  // const exampleService = new MockExampleService()
  // Ejemplo de imagenes
  // const appImgUrlAssets = new AppImgUrlAssets()
  // const logoHeader = appImgUrlAssets.logoHeader

  // Estados de la vista
  // const [urlImg, setUrlImg] = useState('')

  // Ejemplo de servicios al momento de cargar la vista
  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const res = await exampleService.getUrlImg()

  //       setUrlImg(res)
  //     } catch (err) {
  //       // console.error(err)
  //       if (err === 'Invalid data') {
  //         // setState();
  //       }
  //     }
  //   }

  //   fetchData()
  // }, [])

  return (
    <Grid container>
      <Grid item sm={12}>
        <BlueScreenSection />
      </Grid>
    </Grid>
  )
}
