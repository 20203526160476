import React, { useEffect, useState } from 'react'
import { AppFormComponent } from '@app/components/app-form-component/AppFormComponent'
import { ValidateFormService } from '@mod/lead-capture/services/validate-form.service'
// import { AforeLeadsContactService } from '@mod/lead-capture/services/afore-leads-contact.service'
import { MockAforeLeadsContactService } from '@mod/lead-capture/services/mock-afore-leads-contact.service'
import ComponentInput from '@app/components/app-input-component/AppInputComponent'
import { AppInputStatesEnum } from '@app/enums/app.input-states.enum'

export const FormSection: React.FC = () => {
  // Services
  const validateFormService = new ValidateFormService()
  // const ALCService = new AforeLeadsContactService() // Service
  const ALCService = new MockAforeLeadsContactService() // Mock service

  // States
  const [name, setName] = useState({ campo: '', valido: AppInputStatesEnum.Initial })
  const [email, setEmail] = useState({ campo: '', valido: AppInputStatesEnum.Initial })
  const [phone, setPhone] = useState({ campo: '', valido: AppInputStatesEnum.Initial })
  const [currentCopy, setCurrentCopy] = useState('')

  // Presenter Layer
  const handlerOnClick = async () => {
    try {
      const IsValid = validateFormService.validateForm({
        name: name.campo,
        email: email.campo,
        phone: phone.campo
      })
      if (
        IsValid.name === true &&
        IsValid.email === true &&
        IsValid.phone === true
      ) {
        const idSession = 'uuid_principal_user'
        const Body = {
          full_name: name.campo,
          phone_number: phone.campo,
          email: email.campo,
          id_session: idSession,
          origin
        }

        const Res = await ALCService.setAforeLeadsContact(Body)

        if (!Res.Error) {
          alert(
            'todo bien'
          )
        } else {
          alert(
            'Ha ocurrido un error en la búsqueda, por favor intente de nuevo.'
          )
        }
      } else {
        if (IsValid.name === false) {
          setName({ campo: '', valido: AppInputStatesEnum.Invalid })
        }
        if (IsValid.email === false) {
          setEmail({ campo: '', valido: AppInputStatesEnum.Invalid })
        }
        if (IsValid.phone === false) {
          setPhone({ campo: '', valido: AppInputStatesEnum.Invalid })
        }
      }
    } catch {
      alert(
        'Ha ocurrido un error en la búsqueda, por favor intente de nuevo.'
      )
    }
  }

  const onChangeNameState = (e: React.ChangeEvent<HTMLInputElement>) => {
    try {
      const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const IsValid = validateFormService.validateForm({
          name: e.target.value,
          email: '',
          phone: ''
        })

        const NewState = IsValid.name ? AppInputStatesEnum.Valid : AppInputStatesEnum.Invalid

        setName({
          campo: e.target.value,
          valido: NewState
        })
      }
      handleChange(e)
    } catch (error) {
      alert('Por favor, intente de nuevo.')
    }
  }
  const onChangeEmailState = (e: React.ChangeEvent<HTMLInputElement>) => {
    try {
      const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const IsValid = validateFormService.validateForm({
          name: '',
          email: e.target.value,
          phone: ''
        })

        const NewState = IsValid.email ? AppInputStatesEnum.Valid : AppInputStatesEnum.Invalid

        setEmail({
          campo: e.target.value,
          valido: NewState
        })
      }
      handleChange(e)
    } catch (error) {
      alert('Por favor, intente de nuevo.')
    }
  }
  const onChangePhoneState = (e: React.ChangeEvent<HTMLInputElement>) => {
    try {
      const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const IsValid = validateFormService.validateForm({
          name: '',
          email: '',
          phone: e.target.value
        })

        const NewState = IsValid.phone ? AppInputStatesEnum.Valid : AppInputStatesEnum.Invalid

        setPhone({
          campo: e.target.value,
          valido: NewState
        })
      }
      handleChange(e)
    } catch (error) {
      alert('Por favor, intente de nuevo.')
    }
  }
  const copyOptions: string[] = ['¡Hagamos una estrategia y logremos tus metas!', '¡Nosotros te orientamos para hacer crecer tu cuenta de Afore!', 'Tenemos un experto en planes de retiro listo para asesorarte', 'Toma el control de tu futuro ¡Hoy!']

  // useEffect(() => {
  //   const startDate = new Date() // Fecha de inicio
  //   const millisecondsPerDay = 24 * 60 * 60 * 1000 // Milisegundos por día

  //   const rotationTimeout = setInterval(() => {
  //     const currentDate = new Date() // Fecha actual
  //     const daysElapsed = Math.floor((currentDate.getTime() - startDate.getTime()) / millisecondsPerDay) // Días transcurridos
  //     const rotationIndex = daysElapsed % copyOptions.length // Cálculo del índice de rotación

  //     setCurrentCopy(copyOptions[rotationIndex])
  //   }, millisecondsPerDay) // Cambio de copia cada día

  //   const initialCopyIndex: number = Math.floor((new Date().getTime() - startDate.getTime()) / millisecondsPerDay) % copyOptions.length
  //   setCurrentCopy(copyOptions[initialCopyIndex])

  //   return () => clearInterval(rotationTimeout) // Limpiar el intervalo al desmontar el componente
  // }, [copyOptions])

  useEffect(() => {
    const rotationTimeout = setInterval(() => {
      const currentDate = new Date()
      const rotationIndex = Math.floor(currentDate.getTime() / 60000) % copyOptions.length

      setCurrentCopy(copyOptions[rotationIndex])
    }, 60000) // Cambio de copia cada minuto

    const initialCopyIndex = Math.floor(new Date().getTime() / 60000) % copyOptions.length
    setCurrentCopy(copyOptions[initialCopyIndex])

    return () => clearInterval(rotationTimeout) // Limpiar el intervalo al desmontar el componente
  }, [])

  // View Layer
  return (
    <>
      <div>
       <AppFormComponent
          hadleOnclick={handlerOnClick}
          mainTextForm={currentCopy}
          subTextForm='Déjanos tus datos para comenzar'
          bkg='#F0FBFE'
          wid='331px'
          heig='548px'
          textBtn='Enviar'
          pBtom='20px'
        >
        <ComponentInput
          value={name}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangeNameState(e)}
          type='text'
          name='name'
          label='Nombre completo'
          disp='flex'
          w='292.8px'
          h='38.4px'
          alignIt='flex-end'
          fontSize='12px'
          clrLabel='#333333'
          mLabel='0px'
          padLabel='10px 0px 1px'
          tpM='25px'
          height='80px'
          mrtp='1px'
        />
         <ComponentInput
            value={email}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangeEmailState(e)}
            type='text'
            name='email'
            label='Correo'
            disp='flex'
            w='292.8px'
            h='38.4px'
            alignIt='flex-end'
            fontSize='12px'
            clrLabel='#333333'
            mLabel='0px'
            padLabel='10px 0px 1px'
            tpM='25px'
            height='80px'
            mrtp='1px'
          />

          <ComponentInput
            value={phone}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangePhoneState(e)}
            type='number'
            name='phone'
            label='Correo'
            disp='flex'
            w='292.8px'
            h='38.4px'
            alignIt='flex-end'
            fontSize='12px'
            clrLabel='#333333'
            mLabel='0px'
            padLabel='10px 0px 1px'
            tpM='25px'
            height='80px'
            mrtp='1px'
          />
        </AppFormComponent>
      </div>
    </>

  )
}
