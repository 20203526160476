import styled from 'styled-components'

interface SelectorContainerProps {
    width?: string
    outline: string
}

export const SelectorContainer = styled.div<SelectorContainerProps>`
  width: ${(props) => props.width || '290px'};
  height: 36px;
  border: 1px solid #CAD2DB;
  border-radius: 8px;
  outline: ${(props) => props.outline};
`

export const InputGroup = styled.div`
  width: 290px;
  height: 20px;
  padding: 5px 16px;
  display:flex;
  align-items: center;
  justify-content: start;
  gap: 7px;
`
// se cabio por un div
export const Selector = styled.div`
  width: 12%;
  border: none;
`
// se cabio por un div
export const Options = styled.div`
  width: 30%;
`

interface InputProps {
  width?: string;

}
export const Input = styled.input<InputProps>`
  width: ${(props) => props.width || '29%'};
  outline: none;
  border: none;
  min-width: 30px;
`
export const ErrorMsg = styled.p<InputProps>`
 color:red;
`
