import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import GeneralContainerComponent from '@app/components/general-container-component/GeneralContainerComponent'
// import * as S from './CreateAccount.styled'
import { FormTitleButtonComponent } from '@app/components/app-form-title-component/AppFormTitleComponent'
import LabelWithoutInputComponent from '@app/components/app-label-without-input-component/AppLabelWithoutInputComponent'
import { IneInputSection } from './sections/IneInputSection'
import { RegistrationIdentificationService } from './services/upload-ine.service'
import { ValidateFilesService } from './services/validate-files.service'
import { useRegistrationContext } from '@mod/create-account/services/RegistrationContex'
import ModalErrorIne from './sections/components/ModalErrorINE'
// import incode from './services/incode.service'

export const RegistrationIdentificationView: React.FC<{}> = () => {
  const uploadIneService = new RegistrationIdentificationService()
  const filesValidator = new ValidateFilesService()
  const { registrationData } = useRegistrationContext()
  const contextId = registrationData.id
  const navigate = useNavigate()
  // incode
  // const [session, setSession] = useState<any>()
  // const [step, setStep] = useState(0)

  // useEffect(() => {
  //   incode && incode
  //     .createSession('ALL', undefined, {
  //       configurationId: process.env.REACT_APP_DEV_INCODE_CONFIG_CODE
  //     })
  //     .then(async (session) => {
  //       await incode.warmup()
  //       setSession(session)
  //     })
  // }, [])

  // function goNext () {
  //   setStep(step + 1)
  // }

  // function handleError () {
  //   alert('Ocurrió, por favor intenta de nuevo')
  // }

  // if (!session) return <p>Loading...</p>
  const [fileStatus, setFileStatus] = useState<File[]>([])
  const [enablebtn, setEnableBtn] = useState(true)
  const [showModal, setShowModal] = useState(false)
  const [modalResponse, setModalResponse] = useState('')

  if (fileStatus.length > 2) {
    setEnableBtn(!enablebtn)
  }

  useEffect(() => {
    if (fileStatus.length >= 2) {
      setEnableBtn(false)
    } else {
      setEnableBtn(true)
    }
  })

  const handleClick = async () => {
    const selectedInputFileFront = fileStatus[0]
    const selectedInputFileBack = fileStatus[1]

    try {
      const ine = [selectedInputFileFront, selectedInputFileBack]
      const formData = new FormData()
      ine.forEach((file) => {
        if (file) {
          formData.append('files', file)
        }
      })
      // if (step === 2) {
      //   incode.processId({ token: session.token })
      // } else {
      //   alert('Debes subir la foto de ambas partes del INE')
      // }

      // validaciones
      const validateFile1 = filesValidator.validateFile(selectedInputFileFront)
      const validateFile2 = filesValidator.validateFile(selectedInputFileBack)

      if (validateFile1.files && validateFile2.files) {
        const res = await uploadIneService.uploadIne(formData, contextId)
        if (res.response === contextId) {
          navigate('/account-statements')
        } else {
          alert('Ocurrió un error, intentalo de nuevo por favor')
        }
      } else {
        const errorMsg = 'Parece que alguno de tus archivos es inválido. Por favor, verifica los archivos e intenta de nuevo'
        setModalResponse(errorMsg)
        setShowModal(true)
      }
    } catch (error: any) {
      // eslint-disable-next-line no-console
      // console.log(error)
      // alert(error.data.message)
      const errorMsg = 'Ocurrió un error subiendo tus archivos, por favor inténtalo de nuevo'
      setModalResponse(errorMsg)
      setShowModal(true)
    }
  }

  return (
    <GeneralContainerComponent>
      <FormTitleButtonComponent
        mainTextForm='Alta de '
        mainTextBlue='identificación'
        subTextForm='Estamos a punto de finalizar el proceso de creación de tu cuenta. Necesitamos que nos compartas algunos documentos adicionales.'
        textBtn='Continuar'
        hadleOnclick={handleClick}
        enablebtn={enablebtn}
      >
      {showModal && <ModalErrorIne modalResponse={modalResponse} setShowModal={setShowModal} showModal={showModal}/>}
        <LabelWithoutInputComponent
          label='Identificación oficial'
          htmlfor=''
        />
        <IneInputSection id='file-1' index={0} files={fileStatus} SetFiles={setFileStatus} labelExtrText='Vista frontal' />
        <IneInputSection id='file-2' index={1} files={fileStatus} SetFiles={setFileStatus} labelExtrText='Vista trasera' />
      </FormTitleButtonComponent>
    </GeneralContainerComponent>
  )
}
