import { AppLucaPayApiGateway } from '@app/api-gateways/app.luca-pay-api-gateway'
import { useState } from 'react'

const setSubmit = (url: string) => {
  const [response, setResponse] = useState<Response | null>(null)
  const [error, setError] = useState<Error | null>(null)

  const fetchData = async (options: any) => {
    try {
      const response = await AppLucaPayApiGateway.post(url, options)

      setResponse(response.data)
    } catch (error) {
      const e = error as Error
      setError(e)
    }
  }

  return { response, error, fetchData }
}

export default setSubmit
