import { IValidateVerifyForm, IResponseValidateVerifyForm } from './dtos/validate-form.dto'
import { IValidateVerifyFormAbstraction } from './validate-form.abstraction'

export class ValidateVerifyFormService implements IValidateVerifyFormAbstraction {
  validateForm (body: IValidateVerifyForm):IResponseValidateVerifyForm {
    const resValidate = {
      code: false
    }

    const regex = /^.{6}$/
    resValidate.code = regex.test(body.code)

    return resValidate
  }
}
