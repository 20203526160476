import { AppLucaPayApiGateway } from '@app/api-gateways/app.luca-pay-api-gateway'
import { AppResponse } from '@app/models/app.response'

export class RegistrationIdentificationService {
  async uploadIne (files: any, id: number | undefined) {
    const response = await AppLucaPayApiGateway.post<AppResponse<string>>(`/api/INE/${id}/addINE`, files, {
      // Se pone el Content-Type con multipart/form-data para que el server sepa que va a recibir archivos binarios
      headers: {
        'Content-Type': 'multipart/form-data; boundary=--------------------------'
      }
    })
    const res: any = response.data
    return res
  }
}
