import React, { useEffect, useState } from 'react'
import GeneralContainerComponent from '@app/components/general-container-component/GeneralContainerComponent'
import ContainerDesktopComponent from '@app/components/general-container-desktop/ContainerDesktopComponent'
import * as S from './CreateAccount.styled'
import AppLabelInputComponente from '@app/components/app-label-input-component/AppLabelInputComponent'
import AppFormButton from '@app/components/app-button-component/AppFormButton'
import ComponentSeparator from '@app/components/app-separator-component/AppSeparatorComponent'
import { CountryPhoneSelectorSection } from './sections/country-phone-selector/CountryPhoneSelectorSection'
import { AppImgUrlAssets } from '@app/assets/app.img-url.assets'
import { PasswordInputsSection } from './sections/password-inputs/PasswordInputSection'
import { CreateAccountService } from './services/create-account.service'
import { useRegistrationContext } from './services/RegistrationContex'
import { useNavigate } from 'react-router-dom'
import ModalBadResponse from './sections/country-phone-selector/components/ModalWarningComponent'
const appImgUrlAssets = new AppImgUrlAssets()
const emaiIcon = appImgUrlAssets.mailIcon

export const CreateAccountView: React.FC<{}> = () => {
  // Services
  const CAService = new CreateAccountService()

  // States
  const [error, setError] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [email, setEmail] = useState('')
  const [pass, setPass] = useState('')
  const [enableBtn, setEnableBtn] = useState(true)
  const [validPass, setValidPass] = useState(false)
  const [validPhone, setValidPhone] = useState(false)
  const [validMail, setValidMail] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [modalResponse, setModalResponse] = useState('')
  // Sus estados

  // context
  const { registrationData, updateRegistrationData } = useRegistrationContext()

  // navigation
  const navigate = useNavigate()
  // Presenter Layer
  const handlerOnClick = async () => {
    const body = {
      phoneNumber,
      email,
      pass
    }

    try {
      const res = await CAService.addAccount(body)
      // eslint-disable-next-line no-console
      console.log(res) // Log the response to inspect its contents

      const { response } = JSON.parse(res) // Try parsing the response as JSON
      const { id, phoneNumber: phone } = response
      updateRegistrationData({ id, phoneNumber: phone })
      navigate('/verify-account')
    } catch (error:any) {
      setShowModal(true)
      setModalResponse(error.data.message)
    }
  }

  // eslint-disable-next-line no-console
  console.log(registrationData)

  const validateEmail = (email: string) => {
    // Expresión regular para validar el formato del correo electrónico
    const emailRegex = /^[^\s@+*{}´¨]+@[^\s@]+\.[^\s@]+$/

    if (!email) {
      return 'El campo de correo electrónico no puede estar vacío.'
    } else if (!emailRegex.test(email)) {
      return 'Por favor, ingresa un correo electrónico válido.'
    }
    setValidMail(true)
    return ''
  }

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    setEmail(value)
    const errorMessage = validateEmail(value)
    setError(errorMessage)
  }

  const validateFields = () => {
    if (validMail && validPass && validPhone) {
      setEnableBtn(false)
    } else {
      setEnableBtn(true)
    }
  }

  // Update enableBtn state whenever input values or error change
  useEffect(() => {
    validateFields()
  }, [validMail, validPass, validPhone])

  return (
    <GeneralContainerComponent>
      {showModal && <ModalBadResponse modalResponse={modalResponse} setShowModal={setShowModal} showModal={showModal}/>}
      <S.TextCreateAccount>
        Crea <span style={{ color: '#00C1E7' }}>tu cuenta</span>
      </S.TextCreateAccount>
      <S.TextCreateAccount c='#505B69' fs='14px' ta='left' fw='400' lh='130%'>
        !Unete a LucaPay y transforma tu experiencia en compras!
      </S.TextCreateAccount>
      <ContainerDesktopComponent>
        <CountryPhoneSelectorSection setPhoneNumber={setPhoneNumber} setValidPhone={setValidPhone}/>
        <AppLabelInputComponente
          label='Ingresa tu correo electrónico'
          placeholder='Dirección de correo electrónico'
          alt='email'
          icon={emaiIcon}
          type='input'
          name='verification_code'
          htmlfor=''
          handleChange={handleEmailChange}
        />
        {error && <p>{error}</p>}
        <PasswordInputsSection setPass={setPass} setValidPass={setValidPass} />
        <AppFormButton
          hadleOnclick={handlerOnClick}
          typeBtn=''
          w='100%'
          text='Siguiente'
          isDisabled={enableBtn}
        />
      </ContainerDesktopComponent>
      <ComponentSeparator text='ó' />
      <S.footerTextDiv>
        <S.TextCreateAccount
          pb='0px'
          pt='0px'
          c='#505B69'
          fs='14px'
          fw='400'
          lh='130%'
        >
          ¿Ya tienes cuenta con nosotros?
        </S.TextCreateAccount>
        <AppFormButton typeBtn='text' w='100%' h='20%' text='Iniciar sesión' />
      </S.footerTextDiv>
    </GeneralContainerComponent>
  )
}
