import React, { Fragment, ReactNode } from 'react'
import * as S from './AppLayout.styled'

import { AppImgUrlAssets } from '@app/assets/app.img-url.assets'

const appImgUrlAssets = new AppImgUrlAssets()
const faceBook = appImgUrlAssets.faceBook
const lucaPayLogo = appImgUrlAssets.lucaPayLogo
const inLogo = appImgUrlAssets.inLogo
const lucaPayLogoW = appImgUrlAssets.lucaPayLogoW
interface AppLayoutProps {
  children: ReactNode;
}

export const AppLayout: React.FC<AppLayoutProps> = ({ children }) => {
  return (
    <Fragment>
      <S.ContainerLayout>
        <S.HeaderLayout>
          <S.ImageLayout src={lucaPayLogo}></S.ImageLayout>
        </S.HeaderLayout>
        {children}
        <S.FooterContainerNav>
          <S.FooterRow className='rights' m='10px 0 0' dis="flex" fw="" ai="start">
            <S.FooterCol w="50%" m="0">
              <S.ImageLayout w="100px" h="auto" src={lucaPayLogoW}></S.ImageLayout>
              <S.FooterRow className='socials' jc="start" m="0">
                <S.FooterCol m="26px 16px 24px 0">
                  <a
                    target='_blank'
                    href='#'
                    rel='noreferrer'
                  >
                    <img
                      width='20px'
                      height='20px'
                      src={inLogo}
                      alt='In'
                    />
                  </a>
                </S.FooterCol>
                <S.FooterCol m="26px 16px 24px 0">
                  <a
                    target='_blank'
                    href='#'
                    rel='noreferrer'
                  >
                    <img
                      width='20px'
                      height='20px'
                      src={faceBook}
                      alt='Facebook'
                    />
                  </a>
                </S.FooterCol>
              </S.FooterRow>
              <S.TextLayout fw="700" fz="16px">Contacto</S.TextLayout>
              <S.TextLayout mb="0">info@lucapay.com</S.TextLayout>
              <S.TextLayout>55-10-52-88-74</S.TextLayout>
            </S.FooterCol>
            <S.FooterCol mw="50%" m="0">
              <S.TextLayout fw="700" fz="16px">Legal</S.TextLayout>
              <S.TextLayout mb="0">Términos y condiciones</S.TextLayout>
              <S.TextLayout>Aviso de privacidad</S.TextLayout>
              <S.TextLayout mb="25.5px">© Copyright LucaPay 2023</S.TextLayout>
              <S.TextLayout mb="0">
                Mitikah, Av. Río Churubusco 601 Xoco, Coyoacán
              </S.TextLayout>
              <S.TextLayout mb="0">C.P.: 03330,</S.TextLayout>
              <S.TextLayout>Ciudad de México, CDMX</S.TextLayout>
            </S.FooterCol>
          </S.FooterRow>
        </S.FooterContainerNav>
      </S.ContainerLayout>
    </Fragment>
  )
}
