import axios from 'axios'
import { AppInterceptors } from './app.interceptors'

// const token = sessionStorage.getItem('r-token') ? sessionStorage.getItem('r-token') : '"r-token" was not found'
// const token = 'Un token de prueba'

export const AppLucaPayApiGateway = axios.create({
  // Prod
  // baseURL: process.env.REACT_APP_PROD_LUCAPAY_API_GATEWAY,
  // Dev
  baseURL: process.env.REACT_APP_DEV_LUCAPAY_API_GATEWAY,
  // Local
  // baseURL: process.env.REACT_APP_LOCAL_LUCAPAY_API_GATEWAY,

  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
    // Authorization: `Bearer ${token}`
  },
  timeout: 10000
})

AppLucaPayApiGateway.interceptors.request.use(
  AppInterceptors.req,
  AppInterceptors.reqErr
)

AppLucaPayApiGateway.interceptors.response.use(
  AppInterceptors.res,
  AppInterceptors.resErr
)
