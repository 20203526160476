import { AppLucaPayApiGateway } from '@app/api-gateways/app.luca-pay-api-gateway'
import { PersonalInfoDto } from './dtos/personal-info.dto'
import { AppResponse } from '@app/models/app.response'

export class PersonalInfoService {
  async addPersonalInfo (body: PersonalInfoDto): Promise<string> {
    let res = ''

    // Aqui en el if, hay que poner todas las validaciones necesarias
    if (body.curp.length === 18) {
      const response = await AppLucaPayApiGateway.post<AppResponse<string>>(`/api/accounts/${body.id}/info`, body)
      res = JSON.stringify(response.data)
    } else {
      throw new Error('CURP is incorrect')
    }
    return res
  }
}
