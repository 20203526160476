import styled from '@emotion/styled'
import { css } from '@emotion/react'

export const ButtonsContainer = styled.div`
    width: 100%;
    display: flex;
    padding-bottom: 15px;
`
export const IneSpan = styled.div`
  width: 100%;
  height: 40px;
  text-align: center;
  font-family: 'Mulish', sans-serif;
  font-style: normal;
  font-weight: 200;
  font-size: 13px;
  line-height: 120%;  
  color: #00C1E7;
`
interface ContainerUploadBtnProps {
  isFile?: boolean
  isError?: boolean
}
export const ContainerUploadBtn = styled.input<ContainerUploadBtnProps>`
  font-size: 14px;
  width: 100%;
  height: 36px;
  border: 1px solid #CAD2DB;
  border-radius: 8px;
  padding: 6px 16px;
  margin: 8px 0px;
  margin-bottom: 2px;
  ${(props) =>
   props.isFile === true &&
    css`
      border: 1px solid #00C1E7;
  `}
  ${(props) =>
   props.isError === true &&
    css`
      border: 1px solid #c00000;
  `}
`
export const LabelContainerBtn = styled.label`
  width: 100%;
`
interface ErrorDivProps {
  valido?: boolean
}
export const ErrorDiv = styled.div<ErrorDivProps>`
  font-size: 14px;
  color: #c00000;
  display: none;
  ${(props) =>
     props.valido === true &&
     css`
      display: flex;
  `}
    
  ${(props) =>
    props.valido === false &&
    css`
      display: none;
    `}
`

export const ErrorSpan = styled.span`
  text-align: left;
`
