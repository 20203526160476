import { AppLucaPayApiGateway } from '@app/api-gateways/app.luca-pay-api-gateway'
import { AppResponse } from '@app/models/app.response'

export class AccountStatementService {
  async uploadDocument (files: any, registrationId: number): Promise<string> {
    try {
      const response = await AppLucaPayApiGateway.post<AppResponse<string>>(
        `/api/accounts/${registrationId}/account-statments`, files, {
          headers: {
            'Content-Type': 'multipart/form-data; boundary=--------------------------'
          }
        }
      )

      const res: any = response.data
      return res
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
      throw new Error('El código de verificación es incorrecto')
    }
  }
}
