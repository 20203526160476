import React, { useState } from 'react'
import ContainerDesktopComponent from '@app/components/general-container-desktop/ContainerDesktopComponent'
import { FormTitleButtonComponent } from '@app/components/app-form-title-component/AppFormTitleComponent'
import ComponentLabelInput from '@app/components/app-label-input-component/AppLabelInputComponent'
import { ValidateVerifyFormService } from '../services/validate-form.service'
import { AppInputStatesEnum } from '@app/enums/app.input-states.enum'
import { VerifyAccountService } from '../services/verify.service'
import { AppImgUrlAssets } from '@app/assets/app.img-url.assets'
import ModalWrongCode from './components/ModalWrongCodeComponent'
import { useRegistrationContext } from '@mod/create-account/services/RegistrationContex'
import { useNavigate } from 'react-router-dom'

const appImgUrlAssets = new AppImgUrlAssets()
const verCodeIcon = appImgUrlAssets.verCodeIcon
const errorIcon = appImgUrlAssets.iconError

export const FormVerifySection: React.FC = () => {
  const navigate = useNavigate()
  const { registrationData } = useRegistrationContext()
  const contextId = registrationData.id
  const contextPhone = registrationData.phoneNumber
  if (!contextId) {
    alert('No hay id')
  }
  const validateVerifyForm = new ValidateVerifyFormService()
  const verifyService = new VerifyAccountService()

  const [code, setCode] = useState({ campo: '', valido: AppInputStatesEnum.Initial })
  const [showModal, setShowModal] = useState(false)
  const [modalResponse, setModalResponse] = useState('')

  const onChangeCodeState = (e: React.ChangeEvent<HTMLInputElement>) => {
    try {
      const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const isValid = validateVerifyForm.validateForm({
          code: e.target.value
        })
        const newState = isValid.code ? AppInputStatesEnum.Valid : AppInputStatesEnum.Invalid

        setCode({
          campo: e.target.value,
          valido: newState
        })
      }
      handleChange(e)
    } catch (error: any) {
      alert(error.data.message)
    }
  }

  const handleOnClick = async () => {
    try {
      const isValid = validateVerifyForm.validateForm({
        code: code.campo
      })
      if (isValid.code) {
        const body = {
          id: contextId,
          code: code.campo
        }
        const res = await verifyService.postVerifyCode(body)
        if (res.response === contextId) {
          navigate('/personal-information')
        } else {
          alert(res)
        }
      }
    } catch (error: any) {
      const errorMsg = 'El código no es el correcto, por favor verifica la información y vuévelo a intentar.'
      setModalResponse(errorMsg)
      setShowModal(true)
    }
  }

  const bluePhoneNumber = contextPhone?.replace(/^(\+\d{2})(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})$/, '$1 $2 $3 $4 $5 $6')
  return (
    <FormTitleButtonComponent
      mainTextForm="Verifica"
      mainTextBlue="tu cuenta"
      subTextForm="Por favor introduce el código de verificación recibido por SMS al número "
      subTextFormBlue={bluePhoneNumber}
      textBtn="Verificar"
      hadleOnclick={async () => { await handleOnClick() }}
    // hadleOnclick={handleOnClick}
    >
      {showModal && <ModalWrongCode modalResponse={modalResponse} setShowModal={setShowModal} showModal={showModal}/>}
      <ContainerDesktopComponent>
        <ComponentLabelInput
          label="Ingresa el código"
          placeholder="Ingresa el código de verificación"
          alt="Código de verificación"
          icon={verCodeIcon}
          type="input"
          value={code}
          name="verification_code"
          htmlfor=""
          handleChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangeCodeState(e)}
          errorString='El código debe contener 6 caracteres'
          errIcon={errorIcon}
          errIconW='20px'
          autoComplt='off'
        />
      </ContainerDesktopComponent>
    </FormTitleButtonComponent>
  )
}
