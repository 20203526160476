import styled from 'styled-components'

interface TextPersonalInformationProps {
  fw?: string;
  fs?: string;
  ta?: string;
  c?: string;
  lh?: string;
}

export const TextPersonalInformation = styled.div<TextPersonalInformationProps>`
  font-weight: ${(props) => props.fw || '800'};
  font-size: ${(props) => props.fs || '24px'};
  line-height: ${(props) => props.lh || '120%'};
  align-items: center;
  text-align: ${(props) => props.ta || 'center'};
  color: ${(props) => props.c || '#101A27'};
`

export const ColumsPersonalInformation = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 16px;
  width: 100%;
`

export const HrPersonalInformation = styled.div`
  height: 0px;
  border: 1px solid #CAD2DB;
  width: 45%;
`

export const ContainerName = styled.div`
  width: 100%;
`

export const SelectDate = styled.select`
  justify-content: space-between;
  align-items: center;
  padding: 0 5px;
  width: 30%;
  height: 36px;
  background: #FFFFFF;
  border: 1px solid #CAD2DB;
  border-radius: 8px;
`

export const ContainerDate = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

interface GeneralSelectProps {
  w?: string;
}
export const GeneralSelect = styled.select<GeneralSelectProps>`
  justify-content: space-between;
  align-items: center;
  padding: 0 5px;
  width: ${(props) => props.w || '100%'};
  height: 36px;
  background: #FFFFFF;
  border: 1px solid #CAD2DB;
  border-radius: 8px;
`
export const SpanError = styled.span`
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 120%;
  color: #919CAA;
  padding-top: 3px;
`

export const ImgError = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 10px;
`

export const ContainerError = styled.div`
  display: flex;
  width: 100%;
  text-align: left;
`
