import React, { useEffect, useState } from 'react'
import GeneralContainerComponent from '@app/components/general-container-component/GeneralContainerComponent'
import * as S from './AccountStatements.styled'
import { FormTitleButtonComponent } from '@app/components/app-form-title-component/AppFormTitleComponent'
import LabelWithoutInputComponent from '@app/components/app-label-without-input-component/AppLabelWithoutInputComponent'
import { UploadAccountStatement } from './sections/UploadAccountStatements'
import { AppImgUrlAssets } from '@app/assets/app.img-url.assets'
// import axios from 'axios'
import { useRegistrationContext } from '@mod/create-account/services/RegistrationContex'
import { AccountStatementService } from './services/account-statements.service'
import { useNavigate } from 'react-router-dom'
import ModalErrorStatements from './sections/components/ModalErrorStatements'

const appImgUrlAssets = new AppImgUrlAssets()
const iconError = appImgUrlAssets.iconError

export const AccountStatementsView: React.FC<{}> = () => {
  const [fileStatus, setFileStatus] = useState<File[]>([])
  const [enablebtn, setEnableBtn] = useState(true)
  const [isChecked, setIsChecked] = useState(false)
  const [errorData, setErrorData] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [modalResponse, setModalResponse] = useState('')

  const navigate = useNavigate()
  const { registrationData } = useRegistrationContext()
  const uploadEcta = new AccountStatementService()

  useEffect(() => {
    if (fileStatus[0] !== undefined && fileStatus[1] !== undefined && fileStatus[0] !== undefined && isChecked) {
      setEnableBtn(false) // Enable the button when both conditions are met
    } else {
      setEnableBtn(true) // Disable the button otherwise
    }
  }, [fileStatus, isChecked])
  // eslint-disable-next-line no-console

  const handleClick = async () => {
    // eslint-disable-next-line no-console
    console.log(registrationData)
    // let id: any
    try {
      // id = registrationData?.id
      const formData = new FormData()
      fileStatus.forEach((file) => {
        formData.append('files', file)
      })
      const response = await uploadEcta.uploadDocument(formData, 170)
      // eslint-disable-next-line no-console
      console.log(response)
      navigate('/successful-registration')
    } catch (error) {
      // eslint-disable-next-line no-console
      // console.log(error)
      // alert('Ocurrió un error al subir los archivos')
      const errorMsg = 'Ocurrió un error subiendo tus archivos, por favor inténtalo de nuevo'
      setModalResponse(errorMsg)
      setShowModal(true)
    }
  }
  // eslint-disable-next-line no-console

  const changeSet = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsChecked(e.target.checked)
    if (isChecked) {
      setErrorData(true)
    } else {
      setErrorData(false)
    }
  }

  return (
    <GeneralContainerComponent>
      <FormTitleButtonComponent
        mainTextForm='Alta de '
        mainTextBlue='estados de cuenta'
        subTextForm='Tú último paso es compartirnos los siguientes documentos en formato PDF a color, sin tachaduras ni alteraciones. Esto nos ayudará a calcular el monto de tu crédito a través de la consulta en buró.'
        textBtn='Finalizar'
        hadleOnclick={handleClick}
        enablebtn={enablebtn}
      >
      {showModal && <ModalErrorStatements modalResponse={modalResponse} setShowModal={setShowModal} showModal={showModal}/>}
        <LabelWithoutInputComponent
          label='Estados de cuenta bancarios'
          htmlfor=''
        />
        <UploadAccountStatement labelExtraText1='' setFileStatus={setFileStatus}/>
        <S.AutorizoContainer>
          <S.AutorizoText>Autorizo hacer una consulta en Buró</S.AutorizoText>
          <input type='checkbox' checked={isChecked} onChange={e => changeSet(e)}/>
        </S.AutorizoContainer>

        <S.TextAccountS c="#505B69" fs="14px" ta="left" fw="400" lh="130%">
          Queremos asegurarte que tus documentos serán tratados con absoluta confidencialidad
          y exclusivamente con el propósito de ofrecerte la mejor opción de financiamiento.
        </S.TextAccountS>
        { !errorData
          ? null
          : (
          <S.ContainerError>
            <S.ImgError src={iconError} />
            <S.SpanError>Aún no has aceptado que consultemos en Buró</S.SpanError>
          </S.ContainerError>
            )
        }
      </FormTitleButtonComponent>
    </GeneralContainerComponent>
  )
}
