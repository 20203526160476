import React, { useEffect, useState } from 'react'
import { GeneralSelectComponent } from './components/GeneralSelectComponent'
import AppLabelInputComponente from '@app/components/app-label-input-component-pi/AppLabelInputComponent'
import * as S from '../PersonalInformation.styled'
import ComponentLabelWithoutInput from '@app/components/app-label-without-input-component/AppLabelWithoutInputComponent'
import { GendersService } from '../services/get-genders.service'
import { PlacesService } from '../services/get-places.service'
import { ValidateFormService } from '@mod/personal-information/services/validate-form.service'
import { AppInputStatesEnum } from '@app/enums/app.input-states.enum'
interface GeneralProps {
  setGender: any;
  setPlace: any;
  curp: any;
  setCurp: any;
}
export const OtherDataSection: React.FC<GeneralProps> = ({ setGender, setPlace, curp, setCurp }) => {
  const validateFormService = new ValidateFormService()

  const [genderGet, setGenderGet] = useState(false)
  const [genders, setGenders] = useState([])
  const [placesGet, setPlacesGet] = useState(false)
  const [birthplaces, setBirthplaces] = useState([])

  const GetGenders = new GendersService()
  const GetPlaces = new PlacesService()

  const getGendersSelect = async () => {
    try {
      const res = await GetGenders.getGendersInfo()
      if (res !== '') {
        setGenders(JSON.parse(res))
      } else {
        alert(
          'Ha ocurrido un error al descargar géneros, por favor intente de nuevo.'
        )
      }
    } catch {
      alert(
        'Ha ocurrido un error, por favor intente de nuevo.'
      )
    }
  }

  const getPlacesSelect = async () => {
    try {
      const res = await GetPlaces.getPlacesInfo()
      if (res !== '') {
        setBirthplaces(JSON.parse(res))
      } else {
        alert(
          'Ha ocurrido un error al descargar géneros, por favor intente de nuevo.'
        )
      }
    } catch {
      alert(
        'Ha ocurrido un error, por favor intente de nuevo.'
      )
    }
  }

  useEffect(() => {
    if (!genderGet && !placesGet) {
      getGendersSelect()
      getPlacesSelect()
      setGenderGet(true)
      setPlacesGet(true)
    }
  }, [genderGet])

  const [genderSelect, setGenderSelect] = useState('')
  const [placeSelect, setPlaceSelect] = useState('')

  useEffect(() => {
    if (genderSelect !== '' && placeSelect !== '') {
      const genderComplete = genderSelect
      const placeComplete = placeSelect
      setGender(genderComplete)
      setPlace(placeComplete)
    }
  }, [genderSelect, placeSelect])

  const onChangeCurpState = (e: React.ChangeEvent<HTMLInputElement>) => {
    try {
      const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const IsValid = validateFormService.validateForm({
          name: '',
          last_name: '',
          last_name_2: '',
          curp: e.target.value
        })

        const NewState = IsValid.curp ? AppInputStatesEnum.Valid : AppInputStatesEnum.Invalid

        setCurp({
          campo: e.target.value,
          valido: NewState
        })
      }
      handleChange(e)
    } catch (error) {
      alert('Por favor, intente de nuevo.')
    }
  }

  return (
    <>
      <ComponentLabelWithoutInput label="Otros datos" htmlfor="">
        <S.ContainerDate>
          <GeneralSelectComponent options={genders} name="Género" id="genders" width="30%" setValue={setGenderSelect}/>
          <GeneralSelectComponent options={birthplaces} name="Lugar de nacimiento" id="birthplaces" width="60%" setValue={setPlaceSelect}/>
        </S.ContainerDate>
      </ComponentLabelWithoutInput>
      <AppLabelInputComponente
        value={curp}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangeCurpState(e)}
        label=""
        placeholder="CURP"
        alt="CURP"
        icon=""
        type="text"
        name="curp"
        htmlfor=""
      />
    </>
  )
}
