import styled from 'styled-components'

export const ModaOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); /* semi-transparent black background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
`
export const ModaContainer = styled.div`
  width: 413px;
  height: 315px;
  background-color: white;
  padding: 32px;
  border-radius: 16px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  position: relative;
  display: flex;
  flex-direction: column;
  font-family: 'Mulish', sans-serif;
  /* Nuevas propiedades para centrar el modal */
  margin: auto;
  text-align: center;
`
export const ButtonBack = styled.button`
  width:349px;
  height:44px;
  background-color: #0E3EC3;
  padding: 16px;
  border-radius: 8px;
   font-size:18px;
 line-height:23px;
 font-weight:700;
 color:#FFFFFF;
 border-color: #0E3EC3;
 display:flex;
 justify-content:center;
 align-items:center;
 font-family: 'Mulish', sans-serif;
 cursor: pointer;
`
export const ButtonInicio = styled.button`
 width:349px;
  height:44px;
  background-color: #FFFFFF;
  padding: 16px;
  border-radius: 8px;
  border-color:#0E3EC3;
  border-style:solid;
  border-width:1px;
   font-size:18px;
 line-height:23px;
 font-weight:700;
 color:#0E3EC3;
 display:flex;
 justify-content:center;
 align-items:center;
 font-family: 'Mulish', sans-serif;
 cursor: pointer;
`
