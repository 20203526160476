import React from 'react'
import * as S from './app-form-title.styled'
import AppFormButton from '@app/components/app-button-component/AppFormButton'
import ContainerDesktopComponent from '@app/components/general-container-desktop/ContainerDesktopComponent'
interface FormTitleComponent {
    mainTextForm: string;
    mainTextBlue: string;
    subTextForm: string;
    subTextFormBlue?: string;
    bkg?: string;
    wid?: string;
    heig?: string;
    textBtn: string;
    pBtom?: string;
    hadleOnclick?: () => void;
    children?: React.ReactNode;
    enablebtn?: boolean
}

export const FormTitleButtonComponent: React.FC<FormTitleComponent> = ({ mainTextForm, mainTextBlue, subTextForm, textBtn, children, subTextFormBlue, hadleOnclick, enablebtn }) => {
  return (
        <S.FormContainer>
            <S.TextForm>{mainTextForm} <span style={{ color: '#00C1E7' }}>{mainTextBlue}</span></S.TextForm>
            <S.TextForm c="#505B69" fs="14px" ta="left" fw="400" lh="130%">
                {subTextForm}
                {subTextFormBlue ? <span style={{ color: '#00C1E7', fontWeight: '700' }}>{subTextFormBlue}</span> : null}
            </S.TextForm>
            <ContainerDesktopComponent>
                {children}
                <AppFormButton hadleOnclick={hadleOnclick} typeBtn="button" w='100%' text={textBtn} isDisabled={enablebtn} />
            </ContainerDesktopComponent>
        </S.FormContainer>
  )
}
