import styled from '@emotion/styled'
import { css } from '@emotion/react'

interface TextAccountSProps {
  fw?: string;
  fs?: string;
  ta?: string;
  c?: string;
  lh?: string;
}

export const TextAccountS = styled.div<TextAccountSProps>`
  font-weight: ${(props) => props.fw || '800'};
  font-size: ${(props) => props.fs || '24px'};
  line-height: ${(props) => props.lh || '120%'};
  align-items: center;
  text-align: ${(props) => props.ta || 'center'};
  color: ${(props) => props.c || '#101A27'};
`

export const SpanLightBlue = styled.div`
  font-size: 12px;
  line-height: 120%;
  color: #00C1E7;
  padding: 0 0 8px 0;
`
export const AutorizoText = styled.p`
color:#505B69;
font-size:16px;
line-height:19.12px;
font-weight:700
`
export const AutorizoContainer = styled.div`
display:flex;
justify-content:space-between;
align-items:center
`

interface ButtonContainerProps {
    position?: string;
    top?: string;
    botm?: string;
    justifyCont?: string;
}

export const ButtonContainer = styled.div<ButtonContainerProps>`
  justify-content: ${(props) => props.justifyCont};
  display: flex;
  width: 100%;
  position: ${(props) => props.position || ''};
  top: ${(props) => props.top || ''};
  bottom: ${(props) => props.botm || ''};
  align-items: center;
  padding: 8px 0;
  @media (max-width: 800px) { }
`

export interface ButtonProps {
    wid?: string;
    h?: string;
    bgck?: string;
    color?: string;
    ff?: string;
    fontw?: string;
    fs?: string;
    mt?: string;
    marginBottom?: string;
    pad?: string;
    m?: string;
    bder?: string;
    ml?: string;
    typeBtn?: string;
    btnBorder?: string;
}

export const Buttom = styled.button<ButtonProps>`
  width: ${(props) => props.wid || '310px'};
  height: ${(props) => props.h || '40px'};
  background: ${(props) => props.bgck || '#0E3EC4'};
  border-radius: 8px;
  color: ${(props) => props.color || '#FFFFFF'};
  font-family: ${(props) => props.ff || 'Elliot Pro'};
  font-style: normal;
  font-weight: ${(props) => props.fontw || '700'};
  font-size: ${(props) => props.fs || '18px'};
  line-height: 23px;
  text-align: center;
  box-shadow: none;
  margin-top: ${(props) => props.mt || ''};
  margin-bottom: ${(props) => props.marginBottom || ''};
  padding: ${(props) => props.pad || ''};
  margin: ${(props) => props.m || ''};
  border: ${(props) => props.bder || 'none'};
  margin-left: ${(props) => props.ml || ''};
  cursor: pointer;
`
export const ButtIcon = styled.img`
  width: ${(props) => props.width || '20px'};
  height: ${(props) => props.height || 'auto'};
`
export const ButtIconContainer = styled.div`
  width: fit-content;
  display: flex;
  align-items: center;
  align-content: center;
  margin-right: 0;
  margin-left: auto;
  justify-self: flex-end;
`
interface ContainerUploadBtnProps {
  isFile?: boolean
}
export const ContainerUploadBtn = styled.input<ContainerUploadBtnProps>`
  font-size: 14px;
  width: 100%;
  height: 36px;
  border: 1px solid #CAD2DB;
  border-radius: 8px;
  padding: 6px 16px;
  margin: 8px 0;
  ${(props) =>
   props.isFile === true &&
    css`
      border: 1px solid #00C1E7;
  `}
`

export const LabelContainerBtn = styled.label`
  width: 100%;
  height: 46px;
  font-family: 'Mulish', sans-serif;
  font-weight: 700;
  line-height: 23px;
  text-align: center;
  cursor: pointer;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  border: 1px solid #0E3EC4;
  background-color: #ffffff;
  color: #0E3EC4;
  padding: 0px 45px;
  font-size: 12px;
  border-radius: 5px;
  margin: 16px 0;
`
export const SpanError = styled.span`
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 120%;
  color: red;
  padding-top: 3px;
`

export const ImgError = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 10px;
`

export const ContainerError = styled.div`
  display: flex;
  width: 100%;
  text-align: left;
`
