import React from 'react'
import { AppInputStatesEnum } from '@app/enums/app.input-states.enum'
import { Container, Label, IconContainer, Icon, InputGroupContainer, Input, Span, ErrorDiv, ErrorSpan } from './app-label-input.syled'

interface ComponentLabelInputProps {
    label?: string;
    labelExtraText?: string;
    labelHeigth?: string;
    htmlfor: string;
    alt: string;
    labelColor?: string;
    labelIcon?: string;
    labelIconW?: string;
    labelIconH?: string;
    icon?: string;
    placeholder: string;
    type: string;
    name: string;
    iconWidth?: string;
    value?: { campo: string, valido: AppInputStatesEnum };
    children?: React.ReactNode;
    inputType?: string;
    validatePhone?: boolean;
    id?: string;
    handleChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    errIconW?: string;
    errIconH?: string;
    errIcon?: string;
    errIconAlt?: string;
    errorString?: string;
    autoComplt?: string;
}

const ComponentLabelInput: React.FC<ComponentLabelInputProps> = ({ label, htmlfor, alt, icon, placeholder, type, name, iconWidth, value, children, inputType, labelIcon, labelExtraText, labelHeigth, labelIconH, labelIconW, labelColor, handleChange, errIcon, errIconW, errIconH, errIconAlt, errorString, autoComplt, id }) => {
  return (
        <>
            <Container>
                <Label h={labelHeigth} htmlFor={htmlfor}>
                  {labelIcon ? <IconContainer><Icon width={labelIconW} height={labelIconH} src={labelIcon} alt={alt} /></IconContainer> : null}
                  <span style={{ color: labelColor }}>{label}</span>
                  {labelExtraText ? <Span>{labelExtraText}</Span> : null}
                </Label>
                {inputType !== 'whitout-i'
                  ? (
                    <InputGroupContainer>
                      {icon ? <IconContainer><Icon width={iconWidth} src={icon} alt={alt} /></IconContainer> : null}
                      {inputType === 'complex' ? <>{ children }</> : <Input autoComplete={autoComplt} onChange={handleChange} type={type} placeholder={placeholder} name={name} value={value?.campo} id={id} />}
                    </InputGroupContainer>
                    )
                  : null
                }
                <ErrorDiv valido={value?.valido || undefined}>
                  <IconContainer><Icon width={errIconW} heigth={errIconH} src={errIcon} alt={errIconAlt}></Icon></IconContainer><ErrorSpan>{errorString}</ErrorSpan>
                </ErrorDiv>
            </Container>
        </>
  )
}

export default ComponentLabelInput
