import React, { useEffect, useState } from 'react'
import GeneralContainerComponent from '@app/components/general-container-component/GeneralContainerComponent'
import * as S from './SuccessfulRegistration.styled'
import { AppImgUrlAssets } from '@app/assets/app.img-url.assets'
import AppFormButton from '@app/components/app-button-component/AppFormButton'
import { useNavigate } from 'react-router-dom'
const appImgUrlAssets = new AppImgUrlAssets()
const successImg = appImgUrlAssets.successImg

export const SuccessfulRegistrationView: React.FC<{}> = () => {
  const [name, setName] = useState('')
  const navigate = useNavigate()
  useEffect(() => {
    const storedName = localStorage.getItem('name')
    if (storedName) {
      setName(storedName)
    }
  }, [])
  return (
    <GeneralContainerComponent>
      <S.TextSuccessfulReg>¡Alta de archivos <span style={{ color: '#00C1E7' }}>exitosa!</span></S.TextSuccessfulReg>
      <S.ImgSuccessfulReg src={successImg}></S.ImgSuccessfulReg>
      <S.TextSuccessfulReg fw="700" fs="16px">¡Felicidades, {name}!</S.TextSuccessfulReg>
      <S.TextSuccessfulReg c="#505B69" fs="14px" ta="left" fw="400" lh="130%">
        Tus documentos han sido recibidos con éxito y han sido enviados a nuestros <span style={{ fontWeight: '700' }}>LucaAnalistas. </span>
        Ellos son los encargados de validar minuciosamente tu información para brindarte la mejor
        experiencia posible en LucaPay.
      </S.TextSuccessfulReg>
      <S.TextSuccessfulReg c="#505B69" fw="700" fs="16px" ta="left" lh="130%">
        Pronto recibirás una notificación con los resultados
      </S.TextSuccessfulReg>
      <S.TextSuccessfulReg c="#505B69" fs="14px" fw="400" ta="left" lh="130%">
        Agradecemos tu confianza en LucaPay, donde nos dedicamos a ofrecerte soluciones financiaras
        confiables y personalizadas. ¡Estamos emocionados por ser parte de tu éxito financiero!
      </S.TextSuccessfulReg>
      <AppFormButton typeBtn="" w="100%" text="Volver al inicio" hadleOnclick={() => navigate('/')}/>
    </GeneralContainerComponent>
  )
}
