import React, { createContext, useContext, useState } from 'react'

// Define the shape of your registration data
interface RegistrationData {
  phoneNumber?: string;
  id?: number
}

// Define the shape of your context value
interface RegistrationContextValue {
  registrationData: RegistrationData;
  updateRegistrationData: (data: RegistrationData) => void;
}

// Create the context
export const RegistrationContext = createContext<RegistrationContextValue>({
  registrationData: { phoneNumber: '', id: 0 },
  updateRegistrationData: () => {}
})

// Define a custom hook to access the context value
export const useRegistrationContext = () => useContext(RegistrationContext)

// Define the provider component
export const RegistrationProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [registrationData, setRegistrationData] = useState<RegistrationData>({
    phoneNumber: '',
    id: 0
  })

  const updateRegistrationData = (data: RegistrationData) => {
    setRegistrationData(data)
  }

  return (
    <RegistrationContext.Provider value={{ registrationData, updateRegistrationData }}>
      {children}
    </RegistrationContext.Provider>
  )
}
