import styled from '@emotion/styled'

export const FormContainer = styled.div`
    width: 100%;
`
interface TextFormProps {
    fw?: string;
    fs?: string;
    ta?: string;
    c?: string;
    lh?: string;
}

export const TextForm = styled.div<TextFormProps>`
  font-weight: ${(props) => props.fw || '800'};
  font-size: ${(props) => props.fs || '24px'};
  line-height: ${(props) => props.lh || '120%'};
  align-items: center;
  text-align: ${(props) => props.ta || 'center'};
  color: ${(props) => props.c || '#101A27'};
  padding: 15px 0px;
`
