import styled from 'styled-components'

export const InputGroup = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 20px;
  padding: 0px 12px;
`

interface InputProps {
    wIn?: string;
}

export const Input = styled.input<InputProps>`
  width: ${(props) => props.wIn || '90%'};
  height: 20px;
  outline: none;
  border: none;
  ::-ms-reveal {
    display: none;
}
`
interface IconContainerProps {
 wIC?: string;
}

export const IconContainer = styled.div<IconContainerProps>`
    width: ${(props) => props.wIC || '10%'};
    height: 20px;
    display: flex;
    align-items: center;
    align-content: center;
`

interface IconProps {
    width?: string
}

export const Icon = styled.img<IconProps>`
    width: ${(props) => props.width || '24px'};
`
