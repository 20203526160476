import { IAforeLeadsContactsRepository } from './afore-leads-contact.repository'
import { IAforeLeadsDto, IResponseAforeLeadsDto } from './dtos/afore-leads-contact.dto'

export class MockAforeLeadsContactService implements IAforeLeadsContactsRepository {
  setAforeLeadsContact (body: IAforeLeadsDto): Promise<IResponseAforeLeadsDto> {
    const res = {
      Operation: 'insert_afore_leads_contact',
      Error: false,
      Message: 'Success'
    } as IResponseAforeLeadsDto

    if (body.origin.length > 0 && body.id_session.length) {
      return new Promise((resolve, reject) => {
        setTimeout(() => resolve(res), 3000)
      })
    } else {
      throw new Error('Origin and id_session must have a value and its length must be greater than 0')
    }
  }
}
