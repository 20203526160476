import { AppEnvironmentUrlAssets } from './app.environment-url.assets'

export class AppImgUrlAssets {
  private appEnvironmentUrlAssets = new AppEnvironmentUrlAssets()

  // Local
  environment = this.appEnvironmentUrlAssets.localBase
  // Prod
  // environment = this.appEnvironmentUrlAssets.prodBase

  logoHeader = `${this.environment}images/logo-header.png`
  check = `${this.environment}images/check.svg`
  logoPrincipal = `${this.environment}images/logo-principal.svg`
  pensionIcon = `${this.environment}icons/pension-icon.svg`
  documentsIcon = `${this.environment}icons/documents-icon.svg`
  coinIcon = `${this.environment}icons/coins-icon.svg`
  stars = `${this.environment}images/stars.svg`
  twitter = `${this.environment}images/twitter.svg`
  youtube = `${this.environment}images/youtube.svg`
  linkedin = `${this.environment}images/linkedin.svg`
  whatsapp = `${this.environment}images/whatsapp.svg`
  closeIcon = `${this.environment}images/x-incon.svg`
  lucaPayLogo = '/assets/images/lucaPayLogo.svg'
  faceBook = '/assets/images/faceLogo.svg'
  inLogo = '/assets/images/inLogo.svg'
  lucaPayLogoW = '/assets/images/lucaPayLogoW.svg'
  successImg = '/assets/images/successImg.svg'
  verCodeIcon = '/assets/images/verCodeIcon.svg'
  userIcon = '/assets/images/user.svg'
  mailIcon = '/assets/images/mail.svg'
  flagIcon = '/assets/images/flag.svg'
  eyeOffIcon = '/assets/images/eyeOff.svg'
  ineIcon = '/assets/images/ine.svg'
  cameraIcon = '/assets/images/photo_camera.svg'
  fileIcon = '/assets/images/upload_file.svg'
  accountIcon = '/assets/images/accountIcon.svg'
  iconError = '/assets/images/IconError.svg'
}
