import React from 'react'
import * as S from './modal-warning.styled'
interface ModalBadResponseProps {
  modalResponse : string
  setShowModal : React.Dispatch<React.SetStateAction<boolean>>
  showModal : boolean
}
export const ModalBadResponse: React.FC<ModalBadResponseProps> = ({ modalResponse, setShowModal, showModal }) => {
  return (
       <S.ModaOverlay className="modal-overlay">
      <S.ModaContainer className="modal">
        <h2>Oh <S.SpanText>no...</S.SpanText></h2>
        <p>{modalResponse}</p>
        <S.ButtonBack className="close-button" onClick={() => setShowModal(!showModal)}>
          Regresar
        </S.ButtonBack>
         <S.ButtonInicio className="close-button" onClick={() => setShowModal(!showModal)}>
          Iniciar sesión
        </S.ButtonInicio>
      </S.ModaContainer>
    </S.ModaOverlay>
  )
}

export default ModalBadResponse
