import { IValidateForm, IResponseValidateForm } from './dtos/validate-form.dto'
import { IValidateFormAbstraction } from './validate-form.abstraction'

export class ValidateFormService implements IValidateFormAbstraction {
  validateForm (body: IValidateForm):IResponseValidateForm {
    const resValidate = {
      name: false,
      phone: false,
      email: false
    }

    resValidate.name = /^[a-zA-ZÀ-ÿ\s]{1,40}$/.test(body.name)
    resValidate.email = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,63})$/i.test(body.email)
    resValidate.phone = /^\d{10}$/.test(body.phone)

    return resValidate
  }
}
