import { css } from '@emotion/react'
import styled from '@emotion/styled'

export const ButtSpan = styled.span`
  margin-right: 0;
  margin-left: auto;
  color: #2B5DE8;
  font-family: 'Mulish', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 120%;
`

export const InputGroupContainer = styled.div`
  display: flex;
  padding: 10px 0px;
`

export const ButtIconContainer = styled.div`
    width: fit-content;
    display: flex;
    align-items: center;
    align-content: center;
    margin-right: 0;
    margin-left: auto;
    justify-self: flex-end;
`

interface IconProps {
  width?: string
  heigth?: string
}

export const ButtIcon = styled.img<IconProps>`
    width: ${(props) => props.width || '26px'};
    height: ${(props) => props.width};
`
interface ButtonContainerProps {
    position?: string;
    top?: string;
    botm?: string;
    justifyCont?: string;
}

export const ButtonContainer = styled.div<ButtonContainerProps>`
  justify-content: ${(props) => props.justifyCont};
  display: flex;
  width: 100%;
  position: ${(props) => props.position || ''};
  top: ${(props) => props.top || ''};
  bottom: ${(props) => props.botm || ''};
  align-items: center;
  padding: 0px;
  @media (min-width: 800px) {
    max-width: 50%;
    margin: auto;
  }
`
interface LabelButtonContainerProps {
    position?: string;
    top?: string;
    botm?: string;
    justifyCont?: string;
    wid?: string
    h?: string
}
export const LabelButtonContainer = styled.label<LabelButtonContainerProps>`
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
  background-color: '#ffffff';
  color: '#0E3EC4';
  padding: 0px 20px;
  font-weight: 400;
  font-size: 14px;
  border-radius: 5px;
  width: 100%;
  height: ${(props) => props.h || '40px'};
  font-family: 'Elliot Pro';
  font-style: normal;
  line-height: 23px;
  text-align: center;
  box-shadow: none;
  cursor: pointer;
  margin: 0px;
`

export interface ButtonProps {
    wid?: string;
    h?: string;
    bgck?: string;
    color?: string;
    ff?: string;
    fontw?: string;
    fs?: string;
    mt?: string;
    marginBottom?: string;
    pad?: string;
    m?: string;
    bder?: string;
    ml?: string;
    typeBtn?: string;
    btnBorder?: string;
    enableBtn?: boolean;
}

export const Buttom = styled.button<ButtonProps>`
  width: ${(props) => props.wid || '310px'};
  height: ${(props) => props.h || '40px'};
  background: ${(props) => (props.enableBtn ? props.bgck || '#CCCCCC' : '#0E3EC4')}; 
  border-radius: 8px;
  color: ${(props) => props.color || '#FFFFFF'};
  font-family: ${(props) => props.ff || 'Elliot Pro'};
  font-style: normal;
  font-weight: ${(props) => props.fontw || '700'};
  font-size: ${(props) => props.fs || '18px'};
  line-height: 23px;
  text-align: center;
  box-shadow: none;
  margin-top: ${(props) => props.mt || ''};
  margin-bottom: ${(props) => props.marginBottom || ''};
  padding: ${(props) => props.pad || ''};
  margin: ${(props) => props.m || ''};
  border: ${(props) => props.bder || 'none'};
  margin-left: ${(props) => props.ml || ''};
  cursor: ${(props) => (props.enableBtn ? 'not-allowed' : 'pointer')};

  ${(props) =>
    props.typeBtn === 'secondary' &&
    css`
      border: ${props.bder || '1px solid #0E3EC4;'};
      background-color: ${props.color || '#ffffff'};
      color: ${props.color || '#0E3EC4'};
      text-decoration: underline;
      font-weight: 400;
      font-size: 14px;
    `}
  ${(props) =>
    props.typeBtn === 'text' &&
    css`
      border: ${props.bder || 'none'};
      background-color: ${props.color || '#ffffff'};
      color: ${props.color || '#00C1E7'};
      text-decoration: underline;
      font-weight: 400;
      font-size: 14px;
    `}
  ${(props) =>
    props.typeBtn === 'upload' &&
    css`
      display: flex;
      gap: 8px;
      align-items: center;
      justify-content: center;
      border: ${props.bder || '1px solid #0E3EC4;'};
      background-color: ${props.color || '#ffffff'};
      color: ${props.color || '#0E3EC4'};
      padding: 0px 20px;
      font-weight: 400;
      font-size: 14px;
      border-radius: 5px;
    `}
    ${(props) =>
    props.typeBtn === 'labelUpload' &&
    css`
      display: flex;
      gap: 8px;
      align-items: center;
      justify-content: center;
      border: ${props.bder || '1px solid #0E3EC4;'};
      background-color: ${props.color || '#ffffff'};
      color: ${props.color || '#0E3EC4'};
      padding: 0px;
      font-weight: 400;
      font-size: 14px;
      border-radius: 5px;
    `}
`
