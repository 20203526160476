import React, { useEffect, useState } from 'react'
import * as S from '../AccountStatements.styled'
import ComponentLabelInput from '@app/components/app-label-input-component/AppLabelInputComponent'
import { AppImgUrlAssets } from '@app/assets/app.img-url.assets'
import { BtnUploadComponent } from './components/BtnUploadComponent'

const appImgUrlAssets = new AppImgUrlAssets()
const accountIcon = appImgUrlAssets.accountIcon

interface ComponentIneInputProps{
  labelExtraText1: string;
  setFileStatus: React.Dispatch<React.SetStateAction<File[]>>
}

export const UploadAccountStatement: React.FC<ComponentIneInputProps> = ({ labelExtraText1, setFileStatus }) => {
  // eslint-disable-next-line no-console
  const [files, SetFiles] = useState<File[]>([])

  useEffect(() => {
    setFileStatus(files)
  }, [files])
  return (
    <>
      <ComponentLabelInput
        label="Estado de cuenta 1"
        labelHeigth='40px'
        labelExtraText={labelExtraText1}
        placeholder=""
        alt="estado_c1"
        labelIcon={accountIcon}
        type="input"
        name="estado_c1"
        htmlfor=""
        labelIconW='33px'
        labelIconH='40px'
        inputType='whitout-i'
      />
      <BtnUploadComponent id="file-1" w="100%" h="46px" justifyButton="center" typeBtn="file" index= {0} SetFiles={SetFiles} files={files}/>
      <S.SpanLightBlue>Recuerda subir el documento en formato PDF a color, sin tachaduras ni alteraciones.</S.SpanLightBlue>
      <ComponentLabelInput
        label="Estado de cuenta 2"
        labelHeigth='40px'
        labelExtraText={labelExtraText1}
        placeholder=""
        alt="estado_c2"
        labelIcon={accountIcon}
        type="input"
        name="estado_c2"
        htmlfor=""
        labelIconW='33px'
        labelIconH='40px'
        inputType='whitout-i'
      />
      <BtnUploadComponent id="file-2" w="100%" h="46px" justifyButton="center" typeBtn="file" index= {1} SetFiles={SetFiles} files={files}/>
      <S.SpanLightBlue>Recuerda subir el documento en formato PDF a color, sin tachaduras ni alteraciones.</S.SpanLightBlue>
      <ComponentLabelInput
        label="Estado de cuenta 3"
        labelHeigth='40px'
        labelExtraText={labelExtraText1}
        placeholder=""
        alt="estado_c3"
        labelIcon={accountIcon}
        type="input"
        name="estado_c3"
        htmlfor=""
        labelIconW='33px'
        labelIconH='40px'
        inputType='whitout-i'
      />
      <BtnUploadComponent id="file-3" w="100%" h="46px" justifyButton="center" typeBtn="file" index= {2} SetFiles={SetFiles} files={files}/>
      <S.SpanLightBlue>Recuerda subir el documento en formato PDF a color, sin tachaduras ni alteraciones.</S.SpanLightBlue>
    </>
  )
}
