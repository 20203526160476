import React, { useState, useRef, useEffect } from 'react'
import * as S from './div-special-inputs.styled'

interface DivToSpecialInputsComponentProps {
    children: React.ReactNode;
}

export const DivToSpecialInputsComponent: React.FC<DivToSpecialInputsComponentProps> = ({ children }) => {
  // outline logic
  const [isClicked, setIsClicked] = useState(false)
  const divRef = useRef<HTMLDivElement>(null)

  const handleClick = () => {
    setIsClicked(true)
  }

  const handleOutsideClick = (event: MouseEvent) => {
    if (divRef.current && !divRef.current.contains(event.target as Node)) {
      setIsClicked(false)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick)
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick)
    }
  }, [])

  return (
        <>
            <S.DivSpecialInputsContainer ref={divRef} outline={isClicked ? '2px solid black' : 'none'} onClick={handleClick}>
                {children}
            </S.DivSpecialInputsContainer>
        </>
  )
}

export default DivToSpecialInputsComponent
