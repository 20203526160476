import React, { useEffect, useState } from 'react'
import { Grid } from '@mui/material'
import { AnExampleSection } from './sections/AnExampleSection'
import { MockExampleService } from './services/mock-example.service'
// import { ExampleService } from './services/example.service'

export const HomeView: React.FC<{}> = () => {
  // const exampleService = new ExampleService()
  const exampleService = new MockExampleService()

  const [urlImg, setUrlImg] = useState('')

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await exampleService.getUrlImg()

        setUrlImg(res)
      } catch (err) {
        // console.error(err)
      }
    }

    fetchData()
  }, [])

  return (
    <Grid container>
      <Grid item xs={12} sm={4}>
        <AnExampleSection />

        <p>
          Dato obtenido: {urlImg.length > 0 ? urlImg : 'Loading...'}
        </p>
      </Grid>

      <Grid item xs={12} sm={5}>
        <p>
          Un componente
        </p>
      </Grid>

      <Grid item xs={12} sm={3} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <p>
          Otro componente
        </p>
      </Grid>
    </Grid>
  )
}
