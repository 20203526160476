import React, { useState } from 'react'
import * as S from './input-password.styled'

interface InputPasswordComponentProps {
  plhl: string;
  iconWidth?: string;
  icon: string;
  alt: string;
  wInput?: string;
  wIconContainer?: string;
  onPasswordChange: (password: string) => void;
}

const InputPasswordComponent: React.FC<InputPasswordComponentProps> = ({ plhl, iconWidth, icon, alt, wInput, wIconContainer, onPasswordChange }) => {
  const [password, setPassword] = useState<string>('')
  const [viewPass, setViewPass] = useState<boolean>(false)

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    setPassword(value)
    onPasswordChange(value)
  }

  return (

        <>
            <S.InputGroup>
                <S.Input wIn={wInput} placeholder={plhl} value={password} type= { viewPass ? '' : 'password' } onChange={handleInputChange}/>
                <S.IconContainer wIC={wIconContainer}>
                    <S.Icon width={iconWidth} src={icon} alt={alt} onClick={() => setViewPass(!viewPass)}/>
                </S.IconContainer>
            </S.InputGroup>
        </>
  )
}

export default InputPasswordComponent
