import React, { ChangeEvent, useState } from 'react'
import * as S from './ine-input-section.styled'
import ComponentLabelInput from '@app/components/app-label-input-component/AppLabelInputComponent'
import { AppImgUrlAssets } from '@app/assets/app.img-url.assets'
import ButtonFormComponent from '@app/components/app-button-component/AppFormButton'
// import incode from '../services/incode.service'

const appImgUrlAssets = new AppImgUrlAssets()
const ineIcon = appImgUrlAssets.ineIcon
const cameraIcon = appImgUrlAssets.cameraIcon
const fileIcon = appImgUrlAssets.fileIcon

interface ComponentIneInputProps{
  labelExtrText: string;
  files:File[];
  SetFiles : React.Dispatch<React.SetStateAction<File[]>>;
  index: number;
  id: string;
  // session: any;
  // onSuccess: any;
  // onError: any;
  // renderCam: string;
}

export const IneInputSection: React.FC<ComponentIneInputProps> = ({ files, SetFiles, index, labelExtrText, id }) => {
  const [fileName, setFileName] = useState('')
  const [isError, setIsError] = useState<boolean>(false)
  // const buttonRef = useRef(null)

  // const renderCamera = () => {
  //   console.log(session)
  //   incode.renderCamera(renderCam, buttonRef.current, {
  //     onSuccess,
  //     onError,
  //     token: session,
  //     showTutorial: true,
  //     showCustomCameraPermissionScreen: true,
  //     showDoublePermissionsRequest: true
  //   })
  // }
  const uploadFile = async (file: File) => {
    try {
      if (file) {
        if (file.size > 5 * 1024 * 1024) {
          setIsError(true)
        } else if (file.type !== 'application/pdf' && file.type !== 'image/jpeg' && file.type !== 'image/png' && file.type !== 'image/jpg') {
          setIsError(true)
        } else {
          setIsError(false)
        }
        setFileName(file.name)
        const newFiles = [...files]
        newFiles[index] = file
        SetFiles(newFiles)
      }
    } catch (error) {
      setFileName('')
      alert('Error al cargar el archivo')
    }
  }

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files
    uploadFile(files![0])
  }

  return (
    <>
      <ComponentLabelInput
              labelColor='#2B5DE8'
              label="INE"
              labelHeigth='40px'
              labelExtraText={labelExtrText}
              placeholder=""
              alt="ine1"
              labelIcon={ineIcon}
              type="input"
              name="ine1"
              htmlfor=""
              labelIconW='33px'
              labelIconH='40px'
              inputType='whitout-i'
      />
      <S.ContainerUploadBtn disabled placeholder={ fileName !== '' ? fileName : 'Selecciona un archivo' } isFile={ fileName !== '' } isError={isError}></S.ContainerUploadBtn>
      <S.ErrorDiv valido={isError}>
        <S.ErrorSpan>Parece que el archivo es invalido, por favor inténtalo de nuevo.</S.ErrorSpan>
      </S.ErrorDiv>
      {/* <canvas id="canvas" ref={buttonRef} width="300" height="300"></canvas> */}
      <S.ButtonsContainer>
          <ButtonFormComponent
            textwithIcon='Abrir cámara'
            typeBtn='upload'
            w='93%'
            h='46px'
            bIcon={cameraIcon}
            justifyButton='flex-start'
          />
          <ButtonFormComponent
            textwithIcon='Subir archivo'
            typeBtn='labelUpload'
            w='93%'
            h='46px'
            bIcon={fileIcon}
            bIconW='25px'
            bIconH='25px'
            justifyButton='flex-end'
            label={true}
            onChange={(e) => handleChange(e)}
            id={id}
          />
      </S.ButtonsContainer>
      <S.IneSpan>Tamaño máximo por achivo 5Mb</S.IneSpan>
    </>
  )
}
