import { AppLucaPayApiGateway } from '@app/api-gateways/app.luca-pay-api-gateway'
import { IAddAccountDto } from './dtos/add-account.dto'
import { AppResponse } from '@app/models/app.response'

export class CreateAccountService {
  async addAccount (body: IAddAccountDto): Promise<string> {
    let res = ''

    if (body.phoneNumber.length > 0) {
      const response = await AppLucaPayApiGateway.post<AppResponse<string>>('/api/accounts/addAccount', body)
      res = JSON.stringify(response.data)
    } else {
      throw new Error('Phone number must have a valid value and its length must be greater than 0')
    }
    return res

    // Aqui en el if, hay que poner todas las validaciones necesarias
  }
}
