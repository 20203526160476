import { IAnExampleDto } from './dtos/an-example.dto'
import { IRepository } from './repository'

export class MockExampleService implements IRepository {
  getUrlImg (): Promise<string> {
    return new Promise((resolve, reject) => {
      setTimeout(() => resolve(process.env.REACT_APP_FRAMEWORK_ICON || ''), 1000)
    })
  }

  getUrlCV (): Promise<string> {
    return new Promise((resolve, reject) => {
      setTimeout(() => resolve(process.env.REACT_APP_FRAMEWORK_ICON || ''), 1000)
    })
  }

  sendEmail (anExampleDto: IAnExampleDto): Promise<boolean> {
    return new Promise((resolve, reject) => {
      setTimeout(() => resolve(true), 1000)
    })
  }
}
