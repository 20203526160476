import React from 'react'
import * as S from '../../PersonalInformation.styled'

interface SelectDateProps {
    id: string;
    name: string;
    options: any[];
    isDefault: boolean;
    setValue?: any;
}

export const SelectDateComponent: React.FC<SelectDateProps> = ({ id, name, options, isDefault, setValue }) => {
  const selectChange = async (event: React.ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value
    // alert(value)
    setValue(value)
  }
  return (
    <S.SelectDate name={name} id={id} defaultValue={'default'} onChange={selectChange}>
      <option disabled value="default">{isDefault ? name : ''}</option>
      {options.length > 0
        ? options.map((option) => (
          <option value={option.id} key={option.id}>
            {option.name}
          </option>
        )
        )
        : null
      }
    </S.SelectDate>
  )
}
