import styled from '@emotion/styled'
import { css } from '@emotion/react'
import TextField from '@mui/material/TextField'

const colores = {
  borde: '#0076CF',
  error: '#F76800',
  exito: '#0076CF'
}

interface FormularioProps {
  gtc?: string;

}

const Formulario = styled.form<FormularioProps>`
  display: grid;
  grid-template-columns: ${(props) => props.gtc || '1fr 1fr'};

  @media (max-width: 800px) {
    grid-template-columns: 1fr;
    width: 100%;
  }
`

interface DivContainerProps {
    h?: string;
    mtop?: string;
    mright?: string;
    w?: string;
    m?: string;
    tp?: string;
    widthMobile?: string;
    tpM?: string;
}

const DivContainer = styled.div<DivContainerProps>`
  height: ${(props) => props.h || ''};
  margin-top: ${(props) => props.mtop || ''};
  margin-right: ${(props) => props.mright || ''};
  width: ${(props) => props.w || ''};
  position:relative ;
  margin:${(props) => props.m || ''};
  top: ${(props) => props.tp || ''}; 
  @media (max-width: 768px) {
    width: ${(props) => props.widthMobile || '90%'};
     top: ${(props) => props.tpM || ''}; 
  }
`

interface LabelProps {

    fontlbl?: string;
    fontSize?: string;
    disp?: string;
    fontwLabel?: string;
    hLabel?: string;
    padLabel?: string;
    hLbl?: string;
    alignIt?: string;
    clrLabel?: string;
    mLabel?: string;
    mLabelMob?: string;
    fontSizeMob?: string;

}

const Label = styled.label<LabelProps>`
  font-family:${(props) => props.fontlbl || 'eliotprobold'};
  font-size: ${(props) => props.fontSize || '16px'};
  display: ${(props) => props.disp || 'block'};
  font-weight: ${(props) => props.fontwLabel || '400'};
  padding: ${(props) => props.padLabel || '10px 0px'};
  min-height: ${(props) => props.hLabel || '40px'};
  cursor: pointer;
  height: ${(props) => props.hLbl || '30px'};
  align-items: ${(props) => props.alignIt || ''};
  color: ${(props) => props.clrLabel || ''};
  transform: matrix(1, 0, 0, 1, 0, 0);
  margin: ${(props) => props.mLabel || ''};
  @media (max-width: 800px) {
    margin: ${(props) => props.mLabelMob || ''};
    font-size: ${(props) => props.fontSizeMob || '14px'};
  }
`

const GrupoInput = styled.div`
 
 
`

interface InputProps {
    h?: string;
    w?: string;
    minw?: string;
    border?: string;
    m?: string;
    mbtn?: string;
    valido?: string;
    wM?: string;
    wMob?: string;
    maxw?: string;
    mMob?: string;
    hMOb?: string;
    wM425?: string;
    maxw425?: string;
    wM375?: string;
    maxw300?: string;
    wM320?: string;
    pdingMuiInputBase?:string;
}

const Input = styled(TextField) <InputProps>`
  height: ${(props) => props.h || '38.4px'};
  width: ${(props) => props.w || '400px'};
  min-width: ${(props) => props.minw || '110px'};
  font-weight: 400;
  box-sizing: border-box;
  font-size: 16px;
  font-family: 'eliotpro';
  background: #ffffff;
  border: ${(props) => props.border || ' 1px solid #333333'};
  border-radius: 4px;
  color: #333333;
  position:relative ;

  margin: ${(props) => props.m || ''};
  /* transform: matrix(1, 0, 0, 1, 0, 0); */
  margin-bottom: ${(props) => props.mbtn || ''};
  &:hover {
    border: 1.5px solid #004887;
  }
  &:focus {
    border: 3px solid ${colores.borde};
    /* outline: none; */
    /* box-shadow: 3px 0px 30px rgba(163, 163, 163, 0.4); */
  }

  ${(props) =>
        props.valido === 'true' &&
        css`
      /* border: 1px solid #616267; */
      box-shadow: 0px 0px 12px #002e6d;
    `}

  ${(props) =>
        props.valido === 'false' &&
        css`
      border: 2px solid #c00000 !important;
    `}
 .MuiInput-underline:before {
    border-bottom: none;
  }

  && .MuiInput-underline:hover:before {
    border-bottom: none;
  }

 .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input{
  padding:${(props) => props.pdingMuiInputBase || '7.5px 14px !important'};
 }
  /* focused */
  .MuiInput-underline:after {
    border-bottom: none;
  }
  [type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .MuiSelect-icon {
    top: calc(50% - 12px);
    color: rgba(0, 0, 0, 0.54);
    right: -30px !important;
    position: absolute;
    pointer-events: none;
  }
  @media (max-width: 800px) {
    width: ${(props) => props.wM || props.wMob};
    max-width: ${(props) => props.maxw || ''};
    min-width: ${(props) => props.minw || 'unset'};
    margin: ${(props) => props.mMob || ''};
    height: ${(props) => props.hMOb || '38.4px'};
  }

  @media (max-width: 425px) {
    width: ${(props) => props.wM425 || ' '};
    max-width: ${(props) => props.maxw425 || ' '};
  }

  @media (max-width: 376px) {
    width: ${(props) => props.wM375 || ''};
    max-width: ${(props) => props.maxw300 || ''};
  }

  @media (max-width: 320px) {
    width: ${(props) => props.wM320 || ''};
    max-width: ${(props) => props.maxw300 || ''};
  }
`

interface ImgRProps {

    mr?: string;
}

const ImgR = styled.img <ImgRProps>`
  width: 20px;
  height: 20px;
  margin-left: -2px;
  margin-right: ${(props) => props.mr || '3px'};
`

interface ErrorIconProps {

    mr?: string;
}

const ErrorIcon = styled.img <ErrorIconProps>`
  width: 15px;
  height: 15px;
  margin-right: ${(props) => props.mr || '3px'};
`

interface LeyendaErrorProps {
    errormb?: string;
    valido?: string;
    mrtp?: string;
    mrtp2?: string;
}

const LeyendaError = styled.p < LeyendaErrorProps>`
  font-size: 12px;
  margin-bottom: ${(props) => props.errormb || '0px'};
  color: #c00000;
  display: none;
  margin-top: ${(props) => props.mrtp || '10px'};
  ${(props) =>
        props.valido === 'false' &&
        css`
      display: block;
     `}
    
  ${(props) =>
        props.valido === 'true' &&
        css`
      display: none;
    `}

  
`

interface AsteriskValidacionProps {
    fz?: string;
    mb?: string;
    ml?: string;
    ps?: string;
    top?: string;
    left?: string;
    valido?: string;
}

const AsteriskValidacion = styled.p <AsteriskValidacionProps>`
  font-size: ${(props) => props.fz || '14px'};
  margin-bottom: ${(props) => props.mb || '1px'};
  margin-left: ${(props) => props.ml || '2px'};
  position: ${(props) => props.ps || ''};
  top: ${(props) => props.top || ''};
  left: ${(props) => props.left || ''};
  color: #c00000;
  ${(props) =>
        props.valido === 'false' &&
        css`
      color: #c00000;
    `}
`

const ContenedorTerminos = styled.div`
  grid-column: span 2;

  input {
    margin-right: 10px;
  }

  @media (max-width: 800px) {
    grid-column: span 1;
  }
`

const ContenedorBotonCentrado = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-column: span 2;

  @media (max-width: 800px) {
    grid-column: span 1;
  }
`

const Boton = styled.button`
  height: 45px;
  line-height: 45px;
  width: 30%;
  background: #000;
  color: #fff;
  font-weight: bold;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  transition: 0.1s ease all;

  &:hover {
    box-shadow: 3px 0px 30px rgba(163, 163, 163, 1);
  }
`

const MensajeExito = styled.p`
  font-size: 14px;
  color: ${colores.exito};
`

const MensajeError = styled.div`
  height: 45px;
  line-height: 45px;
  background: #f66060;
  padding: 0px 15px;
  border-radius: 3px;
  grid-column: span 2;
  p {
    margin: 0;
  }
  b {
    margin-left: 10px;
  }
`

export {
  Formulario,
  Label,
  GrupoInput,
  Input,
  LeyendaError,
  ContenedorTerminos,
  ContenedorBotonCentrado,
  Boton,
  MensajeExito,
  MensajeError,
  AsteriskValidacion,
  ImgR,
  ErrorIcon,
  DivContainer
}
