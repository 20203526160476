import React, { ChangeEvent, useState } from 'react'
import { AppImgUrlAssets } from '@app/assets/app.img-url.assets'
import * as S from '../../AccountStatements.styled'
// import { useRegistrationContext } from '@mod/create-account/services/RegistrationContex'
// import axios from 'axios'

const appImgUrlAssets = new AppImgUrlAssets()
const fileIcon = appImgUrlAssets.fileIcon
interface ComponentButtonProps {
    w?: string;
    h?: string;
    justifyButton?: string;
    typeBtn: string;
    hadleOnclick?: () => void;
    id?: string;
    index: number
    SetFiles : React.Dispatch<React.SetStateAction<File[]>>
    files:File[]
}

export const BtnUploadComponent: React.FC<ComponentButtonProps> = ({ id, index, SetFiles, files }) => {
  const [fileName, setFileName] = useState('')
  // const { registrationData } = useRegistrationContext()
  // eslint-disable-next-line no-console
  // console.log(registrationData)
  // const { id: accountId } = registrationData
  // eslint-disable-next-line no-console
  // console.log(accountId)
  const uploadFile = async (file: File) => {
    try {
      if (file) {
        setFileName(file.name)
        const newFiles = [...files]
        newFiles[index] = file
        SetFiles(newFiles)
      } else {
        alert('No se ha seleccionado ningún archivo')
      }
    } catch (error) {
      setFileName('')
      alert('Error al cargar el archivo')
    }
  }

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files
    uploadFile(files![0])
  }

  return (
    <div style={{ width: '100%' }}>
      <div>
        <S.ContainerUploadBtn disabled placeholder={fileName !== '' ? fileName : 'Selecciona un archivo'} isFile={fileName !== ''}></S.ContainerUploadBtn>
        <S.LabelContainerBtn htmlFor={id}>
            Seleccionar archivo
            <S.ButtIconContainer><S.ButtIcon src={fileIcon} alt='icon-upload' /></S.ButtIconContainer>
        </S.LabelContainerBtn>
        <input id={id} type="file" accept="application/pdf" onChange={(e) => handleChange(e)} style={{ display: 'none' }} />
      </div>
    </div>
  )
}
