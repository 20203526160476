// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React from 'react'
import { Container } from '@mui/material'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'

// Layout
import { AppLayout } from '@app/layouts/AppLayout'

// Vistas
// import { HomeView } from '@mod/home/HomeView'
// import { AboutView } from '@mod/about/AboutView'
import { VerifyAccountView } from '@mod/verify-account/VerifyAccountView'
// import { LeadCaptureView } from '@mod/lead-capture/LeadCaptureView'
import { SuccessfulRegistrationView } from '@mod/successful-registration/SuccessfulRegistrationView'
import { PersonalInformationView } from '@mod/personal-information/PersonalInformationView'
import { CreateAccountView } from '@mod/create-account/CreateAccounView'
import { RegistrationIdentificationView } from '@mod/registration-identification/RegistrationIdentificationView'
import { AccountStatementsView } from '@mod/account-statements/AccountStatementsView'
import PaymentStore from '@mod/payment-store/PaymentStore'
import { HomeView } from '@mod/home/HomeView'
import { AboutView } from '@mod/about/AboutView'
import { LeadCaptureView } from '@mod/lead-capture/LeadCaptureView'

function App () {
  return (
    <Container
      sx={{
        '&.MuiContainer-root': {
          paddingLeft: '0 ',
          paddingRight: '0 ',
          minHeight: '100vh'
        }
      }}
      maxWidth={false}
    >
      <Router basename='/'>
        <AppLayout>
          <Routes>
            <Route path='/home' element={<HomeView />} />
            <Route path='/about' element={<AboutView />} />
            <Route path='/lead-capture' element={<LeadCaptureView />} />
            <Route path='/' element={<CreateAccountView />} />
            <Route path='/verify-account' element={<VerifyAccountView />} />
            <Route
              path='/personal-information'
              element={<PersonalInformationView />}
            />
            <Route
              path='/successful-registration'
              element={<SuccessfulRegistrationView />}
            />
            <Route
              path='/identification-registration'
              element={<RegistrationIdentificationView />}
            />
            <Route
              path='/account-statements'
              element={<AccountStatementsView />}
            />
            <Route path='/store' element={<PaymentStore />} />
          </Routes>
        </AppLayout>
      </Router>
    </Container>
  )
}

export default App
