import styled from '@emotion/styled'
interface MainContainerViewProps {
  color?: string;
}

export const MainContainerView = styled.div<MainContainerViewProps>`
  flex-direction: row;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${props => props.color};
  width:100%;
  min-height: 60vh;
  background: linear-gradient(90deg, rgba(0,76,151,1) 29%, rgba(0,40,85,1) 100%);
  @media (max-width: 700px) {
    
  }
`
