import React from 'react'
import { InputGroupContainer, IconContainer, Icon } from '@app/components/app-label-input-component/app-label-input.syled'
import DivToSpecialInputsComponent from '../div-to-special-inputs-component/DivSpecialInputsComponent'

interface ComponentLabelProps {
    icon: string;
    iconWidth?: string;
    alt: string;
    children?: React.ReactNode;
}

export const IconDivSpecialComponent: React.FC<ComponentLabelProps> = ({ icon, iconWidth, alt, children }) => {
  return (
        <>
            <InputGroupContainer>
                <IconContainer>
                    <Icon width={iconWidth} src={icon} alt={alt} />
                </IconContainer>
                <DivToSpecialInputsComponent>
                    {children}
                </DivToSpecialInputsComponent>
            </InputGroupContainer>
        </>
  )
}

export default IconDivSpecialComponent
