import React from 'react'
import { CardContainer, BoxItem, MainTextForm, SubTextForm, Buttom, Form } from './app-form.styled'
interface ComponenteFormProps {
  mainTextForm: string;
  subTextForm: string;
  bkg?: string;
  wid?: string;
  heig?: string;
  textBtn?: string;
  pBtom?: string;
  hadleOnclick: () => void;
  children: React.ReactNode;
}

export const AppFormComponent: React.FC<ComponenteFormProps> = ({ mainTextForm, subTextForm, bkg, wid, heig, hadleOnclick, children, textBtn, pBtom }) => {
  return (
    <CardContainer bkg={bkg} wid={wid} heig={heig} pBtom={pBtom}>
      <MainTextForm>
        {mainTextForm}
      </MainTextForm>

      <SubTextForm>
        {subTextForm}
      </SubTextForm>
      <Form>
        {children}
      </Form>
      <SubTextForm fzSubt='12.8px' txAli="start">
        Al hacer clic en “Enviar”, declaro que leí el <b style={{ cursor: 'pointer' }}
          onClick={(e) => {
            e.preventDefault()
            window.open(
              'https://www.principal.com.mx/avisos/aviso-de-privacidad',
              '_blank'
            )
          }}>Aviso de Privacidad</b>
      </SubTextForm>
      <BoxItem>
        <Buttom onClick={hadleOnclick}>
          {textBtn}
        </Buttom>
      </BoxItem>
    </CardContainer>
  )
}
