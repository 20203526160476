import styled from 'styled-components'

interface TextCreateAccountProps {
    fw?: string;
    fs?: string;
    ta?: string;
    c?: string;
    lh?: string;
    pt?: string;
    pb?: string;
}

export const TextCreateAccount = styled.div<TextCreateAccountProps>`
  font-weight: ${(props) => props.fw || '800'};
  font-size: ${(props) => props.fs || '24px'};
  line-height: ${(props) => props.lh || '120%'};
  align-items: center;
  text-align: ${(props) => props.ta || 'center'};
  color: ${(props) => props.c || '#101A27'};
  padding-top: ${(props) => props.pt || '8px'};
  padding-bottom: ${(props) => props.pb || '8px'};;
`
export const footerTextDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 6px;
`
