import React from 'react'
import { Container, Label } from '@app/components/app-label-input-component/app-label-input.syled'

interface ComponentLabelProps {
    label?: string;
    htmlfor: string;
    children?: React.ReactNode;
}

const LabelWithoutInputComponent: React.FC<ComponentLabelProps> = ({ label, htmlfor, children }) => {
  return (
        <>
            <Container>
                <Label htmlFor={htmlfor}>{label}</Label>
                {children}
            </Container>
        </>
  )
}

export default LabelWithoutInputComponent
