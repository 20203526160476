import React from 'react'
import { Buttom, ButtonContainer, ButtIconContainer, ButtIcon, ButtSpan, LabelButtonContainer } from './app-form-button.styled'

interface ComponentButtonProps {
    w?: string;
    h?: string;
    bIcon?: string;
    bIconW?: string;
    bIconH?: string;
    bIconAlt?: string;
    text?: string;
    textwithIcon?: string;
    justifyButton?: string;
    typeBtn: string;
    type?: 'button' | 'submit' | 'reset' | undefined;
    hadleOnclick?: () => void;
    isDisabled?: boolean;
    label?: boolean;
    id?: string;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const ButtonFormComponent: React.FC<ComponentButtonProps> = ({ w, h, text, hadleOnclick, typeBtn, bIcon, bIconW, bIconH, bIconAlt, textwithIcon, justifyButton, isDisabled, type = 'button', label, id, onChange }) => {
  return (
    <>
    {
      label
        ? <ButtonContainer justifyCont={justifyButton}>
            <Buttom disabled={isDisabled} wid={w} h={h} onClick={hadleOnclick} typeBtn={typeBtn} type={type} enableBtn={isDisabled}>
              <LabelButtonContainer htmlFor={id} wid={w} h={h}>
                {text ? <>{text}</> : null}
                {textwithIcon ? <ButtSpan>{textwithIcon}</ButtSpan> : null}
                {bIcon ? <ButtIconContainer><ButtIcon width={bIconW} height={bIconH} src={bIcon} alt={bIconAlt} /></ButtIconContainer> : null }
              </LabelButtonContainer>
              <input id={id} type="file" accept="application/pdf, image/jpeg, image/png, image/jpg, image/webp" onChange={onChange} style={{ display: 'none' }} />
            </Buttom>
          </ButtonContainer>
        : <ButtonContainer justifyCont={justifyButton}>
            <Buttom disabled={isDisabled} wid={w} h={h} onClick={hadleOnclick} typeBtn={typeBtn} type={type} enableBtn={isDisabled}>
              {text ? <>{text}</> : null}
              {textwithIcon ? <ButtSpan>{textwithIcon}</ButtSpan> : null}
              {bIcon ? <ButtIconContainer><ButtIcon width={bIconW} height={bIconH} src={bIcon} alt={bIconAlt} /></ButtIconContainer> : null }
            </Buttom>
         </ButtonContainer>
    }
    </>
  )
}

export default ButtonFormComponent
