import { AppLucaPayApiGateway } from '@app/api-gateways/app.luca-pay-api-gateway'
import { AppResponse } from '@app/models/app.response'
import { VerifyDto } from './dtos/verify.dto'

export class VerifyAccountService {
  async sendSMSCode (id: number | undefined) {
    const res: any = await AppLucaPayApiGateway.post<AppResponse<string>>(`/api/verification/sendSMS/${id}`)

    return res.data
  }

  async postVerifyCode (body: VerifyDto) {
    if (!body.id || !body.code) {
      throw new Error('Faltan datos para completar la operación')
    }
    if (body.code.length > 6) {
      throw new Error('El código no tener más de 6 caracteres')
    }

    const res: any = await AppLucaPayApiGateway.post<AppResponse<string>>(`/api/verification/verificateCode/${body.id}`, body)

    return res.data
  }
}
