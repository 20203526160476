import React from 'react'
import { List, ListItem, ListItemIcon, Typography } from '@mui/material'
import { FiberManualRecord } from '@mui/icons-material'

export const AnExampleSection: React.FC<{}> = () => {
  return (
    <div>
      <Typography component="div">
        Texto de ejemplo.
      </Typography>

      <Typography component="div">
        Bla bla bla...:

        <List component="ul">
          <ListItem button>
            <ListItemIcon>
              <FiberManualRecord/>
            </ListItemIcon>

            Ejemplo
          </ListItem>

          <ListItem button>
            <ListItemIcon>
              <FiberManualRecord/>
            </ListItemIcon>

            Ejemplo
          </ListItem>

          <ListItem button>
            <ListItemIcon>
              <FiberManualRecord/>
            </ListItemIcon>

            Ejemplo
          </ListItem>

          <ListItem button>
            <ListItemIcon>
              <FiberManualRecord/>
            </ListItemIcon>

            Ejemplo
          </ListItem>
        </List>
      </Typography>

      <Typography component="div">
        This app is powered by:

        <List component="ul">
          <ListItem button>
            <ListItemIcon>
              <FiberManualRecord/>
            </ListItemIcon>

            React 18
          </ListItem>

          <ListItem button>
            <ListItemIcon>
              <FiberManualRecord/>
            </ListItemIcon>

            Typescript
          </ListItem>

          <ListItem button>
            <ListItemIcon>
              <FiberManualRecord/>
            </ListItemIcon>

            Material UI 5
          </ListItem>

          <ListItem button>
            <ListItemIcon>
              <FiberManualRecord/>
            </ListItemIcon>

            Axios
          </ListItem>

          <ListItem button onClick={() => window.open('https://www.google.com/', '_blank')}>
            <ListItemIcon>
              <FiberManualRecord/>
            </ListItemIcon>

            Link
          </ListItem>
        </List>
      </Typography>
    </div>
  )
}
