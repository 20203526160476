import styled from 'styled-components'

interface TextSuccessfulRegProps {
  fw?: string;
  fs?: string;
  ta?: string;
  c?: string;
  lh?: string;
}

export const TextSuccessfulReg = styled.div<TextSuccessfulRegProps>`
  font-weight: ${(props) => props.fw || '800'};
  font-size: ${(props) => props.fs || '24px'};
  line-height: ${(props) => props.lh || '120%'};
  align-items: center;
  text-align: ${(props) => props.ta || 'center'};
  color: ${(props) => props.c || '#101A27'};
`

export const ImgSuccessfulReg = styled.img`
  max-width: 200px;
  height: auto;
`
