import React from 'react'
import {
  Input,
  Label,
  GrupoInput,
  LeyendaError,
  DivContainer,
  AsteriskValidacion,
  ErrorIcon
} from './app-input.styled'
import { AppInputStatesEnum } from '@app/enums/app.input-states.enum'

interface ComponenteInputProps {
  value: {
    campo: string;
    valido: AppInputStatesEnum;
  };
  // cambiarEstado?: (value: { campo: string; valido: AppInputStatesEnum }) => void;
  type?: string;
  label?: string;
  placeholder?: string;
  name?: string;
  leyendaError?: string;
  // expresionRegular?: RegExp;
  // funcion?: () => void;
  // minLengthRequired?: number;
  // maxLengthRequired?: number;
  clrLabel?: string;
  // formatDecimales?: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  // onChangeValidation?: () => void;
  // onCurpValidation?: () => void;
  // onTelephoneChange?: () => void;
  // onOnlyTextChange?: () => void;
  // onTextlimitChange?: () => void;
  // onAgeChange?: () => void;
  // onLimit6?: () => void;
  hLabel?: string;
  padLabel?: string;
  disp?: string;
  alignIt?: string;
  fontwLabel?: string;
  fontSize?: string;
  w?: string;
  minw?: string;
  tpM?: string;
  // inputProps?: any;
  maxw?: string;
  maxw300?: string;
  // fz?: string;
  // mb?: string;
  mrtp?: string;
  errormb?: string;
  // ml?: string;
  wM?: string;
  m?: string;
  ps?: string;
  h?: string;
  top?: string;
  left?: string;
  // notNullValidation?: boolean;
  height?: string;
  mLabel?: string;
  // mter?: string;
  wMob?: string;
  mMob?: string;
  mLabelMob?: string;
  fontSizeMob?: string;
  hMOb?: string;
  wM375?: string;
  wM320?: string;
  wM425?: string;
  mba?: string;
  mla?: string;
  tp?: string;
  mright?: string;
  fontlbl?: string;
  hLbl?: string;
  mContainer?: string;
  wContainer?: string;
  pdingMuiInputBase?:string;
}

const ComponentInput: React.FC<ComponenteInputProps> = ({
  value,
  // cambiarEstado,
  pdingMuiInputBase,
  type,
  label,
  onChange,
  placeholder,
  name,
  leyendaError,
  // expresionRegular,
  // funcion,
  // minLengthRequired,
  // maxLengthRequired,
  clrLabel,
  // formatDecimales,
  // onChangeValidation,
  // onCurpValidation,
  // onTelephoneChange,
  // onOnlyTextChange,
  // onTextlimitChange,
  // onAgeChange,
  // onLimit6,
  hLabel,
  padLabel,
  disp,
  alignIt,
  fontwLabel,
  fontSize,
  w,
  minw,
  tpM,
  // inputProps,
  maxw,
  maxw300,
  // fz,
  // mb,
  mrtp,
  errormb,
  // ml,
  wM,
  m,
  ps,
  h,
  top,
  left,
  // notNullValidation,
  height,
  mLabel,
  // mter,
  wMob,
  mMob,
  mLabelMob,
  fontSizeMob,
  hMOb,
  wM375,
  wM320,
  wM425,
  mba,
  mla,
  tp,
  mright,
  fontlbl,
  hLbl,
  mContainer,
  wContainer,
  ...props
}) => {
  return (
    <DivContainer
      h={height}
      widthMobile="100%"
      w={wContainer}
      mtop="-2px"
      m={mContainer}
      tp={tp}
      tpM={tpM}
      mright={mright}
    >
      <Label
        htmlFor={name}
        fontlbl={fontlbl}
        padLabel={padLabel}
        disp={disp}
        alignIt={alignIt}
        hLbl={hLbl}
        hLabel={hLabel}
        clrLabel={clrLabel}
        fontwLabel={fontwLabel}
        fontSize={fontSize}
        mLabel={mLabel}
        mLabelMob={mLabelMob}
        fontSizeMob={fontSizeMob}
      >
        {label}
        <AsteriskValidacion
          ps={ps}
          top={top}
          left={left}
          valido={value.valido || undefined}
          mb={mba}
          ml={mla}
        >
          {value.valido === 'false' ? '*' : null}
        </AsteriskValidacion>
      </Label>

      <GrupoInput>
        <Input
          type={type}
          placeholder={placeholder}
          id={name}
          value={value.campo}
          name={name}
          onChange={onChange}
          // onKeyUp={validacion}
          // pattern={expresionRegular}
          // onBlur={validacion}
          // minLength={minLengthRequired}
          required
          // maxLength={maxLengthRequired}
          // inputProps={inputProps}
          valido={value.valido || undefined}
          wMob={wMob}
          hMOb={hMOb}
          pdingMuiInputBase={pdingMuiInputBase}
          m={m}
          mMob={mMob}
          wM={wM}
          w={w}
          minw={minw}
          h={h}
          wM425={wM425}
          wM375={wM375}
          wM320={wM320}
          maxw={maxw}
          maxw300={maxw300}
          {...props}
        />
      </GrupoInput>
      <LeyendaError valido={value.valido || undefined} mrtp={mrtp} errormb={errormb}>
        <ErrorIcon alt='error' /> {leyendaError}
      </LeyendaError>
    </DivContainer>
  )
}

export default ComponentInput
