import React, { ChangeEvent } from 'react'
import './payment-styles.css'
import { PhoneInput } from 'react-contact-number-input'
import EmailIcon from '@mui/icons-material/Email'
import PhoneIcon from '@mui/icons-material/Phone'
import ButtonWithModal from '@mod/store-modal/store-modal'
import setSubmit from '@app/hooks/useSubmit'

const PaymentStore = () => {
  const options = ['Motocicleta']
  const [paymentState, setPaymentState] = React.useState({
    firstName: '',
    seconName: '',
    lastName: '',
    lastName2: '',
    mobileNumber: '',
    phoneNumber: '',
    email: ''
  })

  const [sellerState, setSellerState] = React.useState({
    name: '',
    branchCode: ''
  })

  const url = 'store/addClientPayLater'
  const { fetchData } = setSubmit(url)
  const urlSeller = 'store/addSeller'
  const data = setSubmit(urlSeller)

  const [showModal, setShowModal] = React.useState(false)

  const handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    setPaymentState((prev) => ({ ...prev, [name]: value }))
  }

  const handleOpenModal = (e: any) => {
    e.preventDefault()
    const sendBody = {
      ...paymentState,
      names: `${paymentState.firstName} ${paymentState.seconName}`
    }

    fetchData(sendBody)

    setShowModal(true)
  }

  const handleCloseModal = () => {
    data.fetchData(sellerState)

    setShowModal(false)
  }

  const handleChangePhone = (e: any) => {
    setPaymentState((prev) => ({ ...prev, mobileNumber: e.phoneNumber }))
  }

  return (
    <div className='screenBody'>
      <div className='screenTitle'>
        <h3>
          Compra ahora, <span className='paymentBold'>Paga despues</span>
        </h3>
      </div>
      <div className='text-content'>
        <p>
          Bienvenido/a al proceso de solicitud de crédito personalizado de
          compra ahora, paga después con LucaPay.
        </p>
        <p>
          Queremos asegurarnos de ofrecerte una experiencia de compra sin
          complicaciones, por eso te pedimos que completes los siguientes datos.
        </p>
      </div>

      <form className='payment-form'>
        <label>
          ¡Completa los campos a continuación y disfruta de la comodidad de
          comprar ahora y pagar después con LucaPay!
        </label>
        <div className='form-group'>
          <label>Ingresa tu nombre</label>
          <div className='twoLinesGroup'>
            <input
              type='text'
              className='payment-input'
              placeholder='Ingresa tu primer nombre'
              name='firstName'
              onChange={handleChangeInput}
            />
            <input
              type='text'
              className='payment-input'
              placeholder='Ingresa tu segundo nombre (Opcional)'
              name='secondName'
              onChange={handleChangeInput}
            />
          </div>
          <div className='twoLinesGroup'>
            <input
              type='text'
              className='payment-input'
              placeholder='Ingresa tu apellido paterno'
              name='lastName'
              onChange={handleChangeInput}
            />
            <input
              type='text'
              className='payment-input'
              placeholder='Ingresa tu apellido materno'
              name='lastName2'
              onChange={handleChangeInput}
            />
          </div>
        </div>
        <div className='form-group'>
          <label>Ingresa tu correo electronico</label>
          <div className='group-inline'>
            <EmailIcon className='icon-borded email-icon' />
            <input
              type='email'
              className='payment-input'
              placeholder='Direccion de correo electronico'
              name='email'
              onChange={handleChangeInput}
            />
          </div>
        </div>
        <div className='form-group oneLine'>
          <div className='w-middle'>
            <label>Ingresa tu numero celular</label>
            <div className='group'>
              <PhoneIcon className='icon-borded' />
              <PhoneInput countryCode='mx' onChange={handleChangePhone} />
            </div>
          </div>

          <div className='w-middle'>
            <label>Ingresa tu telefono fijo (opcional)</label>
            <div className='group phoneNumber'>
              <PhoneIcon className='icon-borded' />
              <input
                type='number'
                name='phoneNumber'
                className='payment-input'
                placeholder='Telefono fijo'
                onChange={handleChangeInput}
              />
            </div>
          </div>
        </div>
        <div className='form-group'>
          <p>
            En caso de tener un crédito aprobado ¡Indicanos cómo planeas
            aprovecharlo al máximo!
          </p>
          <p>
            Esto nos permitirá comprender tus necesidades y brindarte opciones
            de financiamiento flexibles. Tus datos personales estarán protegidos
            y solo serán utilizados por LucaPay para procesar tu solicitud de
            crédito.
          </p>
        </div>
        <div className='form-group'>
          <select className='payment-input selectable' name='selector'>
            {options.map((option, key) => (
              <option value={option} key={key}>{option}</option>
            ))}
          </select>
        </div>

        <button onClick={handleOpenModal} className='button-submit'>
          Enviar solicitud
        </button>
      </form>

      {showModal && (
        <ButtonWithModal onClose={handleCloseModal} setState={setSellerState} />
      )}
    </div>
  )
}

export default PaymentStore
