import React, { useState, useEffect } from 'react'
import GeneralContainerComponent from '@app/components/general-container-component/GeneralContainerComponent'
import ContainerDesktopComponent from '@app/components/general-container-desktop/ContainerDesktopComponent'
import * as S from './VerifyAccount.styled'
import AppFormButton from '@app/components/app-button-component/AppFormButton'
import { FormVerifySection } from './sections/FormSection'
import { VerifyAccountService } from './services/verify.service'
import { useNavigate } from 'react-router-dom'
import { useRegistrationContext } from '@mod/create-account/services/RegistrationContex'

export const VerifyAccountView: React.FC<{}> = () => {
  const navigate = useNavigate()
  const { registrationData } = useRegistrationContext()
  const contextPhoneNumber = registrationData.phoneNumber
  const contextId = registrationData.id
  if (!contextPhoneNumber || !contextId) {
    alert('Ocurrió un error recuperando los datos')
  }
  const verifyService = new VerifyAccountService()
  // Logica para el mensaje apenas se renderiza la pantalla
  const [messageSent, setMessageSent] = useState(false)
  useEffect(() => {
    const sendMessage = async () => {
      try {
        const res = await verifyService.sendSMSCode(contextId)
        console.log(res)
      } catch (error: any) {
        alert(error.data.message)
      }
    }
    if (!messageSent) {
      sendMessage()
      setMessageSent(true)
    }
  }, [])
  // logica para reenviar el mensaje
  const [isButtonDisabled, setIsButtonDisabled] = useState(false)

  const handleResendClick = () => {
    // Lógica para reenviar el código aquí
    const sendNewMessage = async () => {
      await verifyService.sendSMSCode(contextId)
    }
    sendNewMessage()
    // Deshabilitar el botón después de hacer clic
    setIsButtonDisabled(true)
  }

  useEffect(() => {
    // Habilitar el botón después de 30 segundos
    const timer = setTimeout(() => {
      setIsButtonDisabled(false)
    }, 30000)

    // Limpiar el temporizador al desmontar el componente
    return () => clearTimeout(timer)
  }, [])

  const handleNumberChange = () => {
    navigate('/')
  }
  return (
    <GeneralContainerComponent>
      <FormVerifySection />
      <ContainerDesktopComponent>
        <S.ColumsVerifyAccount>
          <S.HrVerifyAccount />
          <S.TextVerifyAccount fw="400" fs="14px">ó</S.TextVerifyAccount>
          <S.HrVerifyAccount />
        </S.ColumsVerifyAccount>
        <S.TextVerifyAccount fw="700" fs="16px">¿No recibiste ningún código?</S.TextVerifyAccount>
        <S.TextVerifyAccount c="#505B69" fs="14px" fw="400">Intenta de nuevo en 30 segundos, por favor</S.TextVerifyAccount>
        <AppFormButton hadleOnclick={handleResendClick} isDisabled={isButtonDisabled} typeBtn="secondary" text="Reenviar código" w="100%" />
        <AppFormButton hadleOnclick={handleNumberChange} typeBtn="text" text="Cambiar número" w="100%" />
      </ContainerDesktopComponent>
    </GeneralContainerComponent>
  )
}
