import React, { useState, useEffect } from 'react'
import { SelectDateComponent } from './components/SelectDateComponent'
import * as S from '../PersonalInformation.styled'
import ComponentLabelWithoutInput from '@app/components/app-label-without-input-component/AppLabelWithoutInputComponent'

const days: { id: string | number; name: number; }[] = []
for (let i = 1; i <= 31; i++) {
  days.push({
    id: i <= 9 ? `0${i}` : i,
    name: i
  })
}
const months = [
  'Enero',
  'Febrero',
  'Marzo',
  'Abril',
  'Mayo',
  'Junio',
  'Julio',
  'Agosto',
  'Septiembre',
  'Octubre',
  'Noviembre',
  'Diciembre'
].map((month, index) => ({
  name: month,
  id: index < 9 ? `0${index + 1}` : index + 1
}))

const years: { id: number; name: number; }[] = []
const fecha = new Date()
const anio = fecha.getFullYear()
const limit = anio - 18
for (let i = 1940; i < limit + 1; i++) {
  years.push({
    id: i,
    name: i
  })
}

const isdefault = true

interface GeneralProps {
  setDateB: any;
}

export const BirthDaySection: React.FC<GeneralProps> = ({ setDateB }) => {
  const [day, setDay] = useState('')
  const [month, setMonth] = useState('')
  const [year, setYear] = useState('')

  useEffect(() => {
    if (day !== '' && month !== '' && year !== '') {
      const dateComplete = day + '/' + month + '/' + year
      setDateB(dateComplete)
    }
  }, [day, month, year])
  return (
    <ComponentLabelWithoutInput label="Fecha de nacimiento" htmlfor="">
      <S.ContainerDate>
        <SelectDateComponent isDefault={isdefault} options={days} name="Día" id="days" setValue={setDay} />
        <SelectDateComponent isDefault={isdefault} options={months} name="Mes" id="months" setValue={setMonth} />
        <SelectDateComponent isDefault={isdefault} options={years} name="Año" id="years" setValue={setYear} />
      </S.ContainerDate>
    </ComponentLabelWithoutInput>
  )
}
