import styled from '@emotion/styled'

interface CardContainerProps {
  bkg?: string;
  wid?: string;
  heig?: string;
  dis?: string;
  wrap?: string;
  ps?: string;
  tp?: string;
  aliIt?: string;
  flexDir?:string;
  borR?: string;
  zIn?:string;
  btn?:string;
  right?:string;
  boxSha?:string;
  fzSubt?:string;
  pBtom?:string;
 }

export const CardContainer = styled.div<CardContainerProps>`
  display:${(props) => props.dis || 'flex'};
  flex-direction: ${(props) => props.flexDir || 'column'}; 
  width:${(props) => props.wid || ''};
  height:${(props) => props.heig || ''};
  background:${(props) => props.bkg || ''};
  border-radius:${(props) => props.borR || '16px'};
  position:${(props) => props.ps || ' '};
  top: ${(props) => props.tp || ' '};
  flex-wrap:${(props) => props.wrap || ' '};
  align-items:${(props) => props.aliIt || 'center'}; 
  z-index:${(props) => props.zIn || ''};
  bottom:${(props) => props.btn || ''};
  right:${(props) => props.right || ''};
  box-shadow:${(props) => props.boxSha || ''};
  padding: 32px 19.2px;
  @media (max-width: 800px) { }
`
interface BoxItemProps {
  posi?: string;
  tp?: string;
  botm?: string;
 }

export const BoxItem = styled.div<BoxItemProps>`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: ${(props) => props.posi || ''};
  top: ${(props) => props.tp || ''};
  bottom: ${(props) => props.botm || ''};;
  justify-content: center;
  align-items: center;
  margin-top: 40px;;
  @media (max-width: 800px) { }
`

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 331px;
  justify-content: center;
  align-items: center;
  margin-bottom: 28px;
  @media (max-width: 800px) { }
`

export const MainTextForm = styled.p`
  width: 292.6px;
  height: 72px;
  font-family: 'Elliot Pro';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: #002855;
  @media (max-width: 800px) { }
`
interface SubTextFormProps {
  fzSubt?: string;
  colorSubt?:string;
  txAli?:string;
 }

export const SubTextForm = styled.p<SubTextFormProps>`
  font-family: 'Elliot Pro';
  font-weight: 400;
  font-size:${(props) => props.fzSubt || '14px'};
  text-align:${(props) => props.txAli || 'center'};
  width: 292.6px;
  height: 32px;
  line-height: 19.2px;
  color:${(props) => props.colorSubt || ''}; 
  margin: 0px;
  @media (max-width: 800px) { }
`

export const SubText = styled.p`
  font-family: 'Elliot Pro';
  width: 234px;
  font-weight: 300;
  font-size: 19.2px;
  font-style: light;
  text-align: center;
  line-height: 19.2px;
  color: #000000;
  margin: 30px 0px;
  @media (max-width: 800px) { }
`

export const MainTextResponse = styled.p`
  font-family: 'Elliot Pro';
  font-weight: 400;
  font-size: 36px;
  text-align: center;
  line-height: 29.2px;
  color: #004C97;
  margin-top: 130px;
  @media (max-width: 800px) { }
`

export interface BtnProps {
  w?: string;
  h?: string;
  bgck?: string;
  clr?: string;
  ff?: string;
  fontw?: string;
  fs?: string;
  mt?: string;
  marginBottom?: string;
  pad?: string;
  m?: string;
  bder?: string;
  ml?: string;
  HoverbackgroundColor?: string;
  hColor?: string;
  activebackgroundColor?: string;
  activehColor?: string;
  mMob900?: string;
  wMob?: string;
  hMob?: string;
  clrMob?: string;
  mMob?: string;
  bgMob?: string;
  ffMob?: string;
  mbMob?: string;
  als?: string;
  DisMob?: string;
  wMob425?: string;
}

export const Buttom = styled.button<BtnProps>`
  width: ${(props) => props.w || '87px'};
  height: ${(props) => props.h || '38px'};
  background: ${(props) => props.bgck || '#004887'};
  border-radius: 40px;
  color: ${(props) => props.clr || '#FFFFFF'};
  font-family: ${(props) => props.ff || 'Elliot Pro'};
  font-style: normal;
  font-weight: ${(props) => props.fontw || '400'};
  font-size: ${(props) => props.fs || '12px'};
  line-height: 18px;
  text-align: center;
  box-shadow: none;
  margin-top: ${(props) => props.mt || ''};
  margin-bottom: ${(props) => props.marginBottom || ''};
  padding: ${(props) => props.pad || ''};
  margin: ${(props) => props.m || ''};
  border: ${(props) => props.bder || 'none'};
  margin-left: ${(props) => props.ml || ''};
  transition: background-color 200ms cubic-bezier(0.4, 0, 0.2, 1);

  &:hover {
    cursor: pointer;
    background-color: ${(props) => props.HoverbackgroundColor || '#004887'};
    color: ${(props) => props.hColor || '#FFFFFF'};
  }

  &:active {
    cursor: pointer;
    background-color: ${(props) => props.activebackgroundColor || '#002E6D'};
    color: ${(props) => props.activehColor || '#FFFFFF'};
  }

  @media (max-width: 950px) {
    margin: ${(props) => props.mMob900 || ''};
  }

  @media (max-width: 800px) {
    width: ${(props) => props.wMob || ''};
    height: ${(props) => props.hMob || '48px'};
    color: ${(props) => props.clrMob || ''};
    margin: ${(props) => props.mMob || ''};
    background: ${(props) => props.bgMob || ''};
    font-family: ${(props) => props.ffMob || ''};
    font-size: 16px;
    margin-bottom: ${(props) => props.mbMob || ' '};
    align-self: ${(props) => props.als || ' '};
    display: ${(props) => props.DisMob || ' '};
  }

  @media (max-width: 335px) {
    width: ${(props) => props.wMob425 || ''};
  }

`
