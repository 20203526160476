import React from 'react'
import * as S from './modal-error-statements.styled'
interface ModalBadResponseProps {
  modalResponse : string
  setShowModal : React.Dispatch<React.SetStateAction<boolean>>
  showModal : boolean
}
export const ModalErrorStatements: React.FC<ModalBadResponseProps> = ({ modalResponse, setShowModal, showModal }) => {
  return (
    <S.ModaOverlay className="modal-overlay">
      <S.ModaContainer className="modal">
        <h2>Oh <span style={{ color: '#00C1E7' }}>no...</span></h2>
        <p>{modalResponse}</p>
        <S.ButtonBack className="close-button" onClick={() => setShowModal(!showModal)}>
          Regresar
        </S.ButtonBack>
      </S.ModaContainer>
    </S.ModaOverlay>
  )
}

export default ModalErrorStatements
