import React from 'react'
import * as S from '../../PersonalInformation.styled'

interface GeneralSelectProps {
    id: string;
    name: string;
    options: any[];
    width: string;
    setValue?: any;
}

export const GeneralSelectComponent: React.FC<GeneralSelectProps> = ({ id, name, options, width, setValue }) => {
  const selectChange = async (event: React.ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value
    // alert(value)
    setValue(value)
  }
  return (
    <S.GeneralSelect name={name} id={id} w={width} defaultValue={'default'} onChange={selectChange}>
      <option disabled value="default">{name}</option>
      {options.length > 0
        ? options.map((option) => (
          <option id={option.id} value={option.key} key={option.key}>
            {option.description}
          </option>
        )
        )
        : null
      }
    </S.GeneralSelect>
  )
}
