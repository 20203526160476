import { AppLucaPayApiGateway } from '@app/api-gateways/app.luca-pay-api-gateway'
import { AppResponse } from '@app/models/app.response'

export class PlacesService {
  async getPlacesInfo (): Promise<string> {
    let res = ''
    const response = await AppLucaPayApiGateway.get<AppResponse<string>>('api/catalogs/BirthPlaces')
    res = JSON.stringify(response.data.response)
    return res
  }
}
