import { IResponseValidateFile } from './dtos/validate-files.dto'
import { IValidateFilesAbstraction } from './validate-files.abstraction'

function sizeValidator (file: File) {
  if (file.size > 5 * 1024 * 1024) {
    return false
  }
  return true
}

function typeValidator (file: File) {
  if (file.type !== 'application/pdf' && file.type !== 'image/jpeg' && file.type !== 'image/png') {
    return false
  }
  return true
}

export class ValidateFilesService implements IValidateFilesAbstraction {
  validateFile (file: File):IResponseValidateFile {
    const resValidate = {
      files: false
    }
    if (sizeValidator(file) && typeValidator(file)) {
      resValidate.files = true
    }

    return resValidate
  }
}
