import styled from '@emotion/styled'
import { css } from '@emotion/react'

export const Container = styled.div`
  padding: 0px;
  width: 100%;
  font-family: 'Mulish', sans-serif;
`

interface LabelProps {
    h?: string
}

export const Label = styled.label<LabelProps>`
 width: 100%;
 height: ${(props) => props.h || '26px'};
 color: #001619;
 font-style: normal;
 font-weight: 700;
 font-size: 16px;
 line-height: 150%;
 display: flex;
 align-items: center;
 gap: 10px;
`
export const Span = styled.span`
  margin-right: 0;
  margin-left: auto;
  justify-self: flex-end;
  color: #7B858F;
  font-family: 'Mulish', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
`

export const InputGroupContainer = styled.div`
  display: flex;
  padding: 8px 0px;
`

export const IconContainer = styled.div`
    width: fit-content;
    display: flex;
    align-items: center;
    align-content: center;
    padding: 5px;
    padding-left: 0px;
`

interface IconProps {
    width?: string
    heigth?: string
}

export const Icon = styled.img<IconProps>`
    width: ${(props) => props.width || '26px'};
    height: ${(props) => props.width};
`

interface InputProps {
    width?: string
    valido?: string
}

export const Input = styled.input<InputProps>`
    width: ${(props) => props.width || '100%'};
    height: 36px;
    border: 1px solid #CAD2DB;
    border-radius: 8px;
    padding: 16px;
    ${(props) =>
        props.valido === 'true' &&
        css`
      border: 1px solid #CAD2DB;
    `}

  ${(props) =>
        props.valido === 'false' &&
        css`
      border: 1px solid red !important;
    `}
`
